import { instance } from './instance';
interface IGetList {
  query: string;
  pageNo: number;
  pageSize: number;
}
const getList = async ({ query = '', pageNo = 1, pageSize = 10 }: IGetList) => {
  try {
    const result = await instance.get(
      `/warranty/registered?q=${query}&page_no=${pageNo}&pagesize=${pageSize}`,
    );
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface IAttachSlip {
  serialNumber: string;
}
const attachSlip = async ({ serialNumber }: IAttachSlip) => {
  try {
    const result = await instance.get(
      `/warranty/registered/${encodeURIComponent(serialNumber)}/attachments/slip`,
    );
    return result.data.url;
  } catch (err: any) {
    return undefined;
  }
};
interface IChangeStatus {
  actionBy: string;
  serialNumber: string;
  status: 'Approved' | 'Rejected' | 'Incompleted' | 'Pending';
}
const changeStatus = async ({ actionBy, serialNumber, status }: IChangeStatus) => {
  try {
    await instance.post(
      `/warranty/registered/${encodeURIComponent(serialNumber)}/campaign/${status}/${actionBy}`,
    );
    return true;
  } catch (err: any) {
    return undefined;
  }
};
const checkVerifySerialNumber = async (serialNumber: string) => {
  try {
    const result = await instance.post(`/warranty/registered/byAgent/verifySerialNumber`, {
      serialNumber: serialNumber,
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};

const calculateSerialNumberExpiryDate = async (serialNumber: string, registeredTime: number) => {
  try {
    const result = await instance.post(
      `/warranty/registered/byAgent/calculateProductWarrantyExpiration`,
      {
        registeredTime: registeredTime,
        serialNumber: serialNumber,
      },
    );
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface IGetAvailableCampaign {
  registeredTime: number;
  sku: string;
  productType: string;
}
const getAvailableCampaign = async ({
  registeredTime,
  sku,
  productType,
}: IGetAvailableCampaign) => {
  try {
    const result = await instance.get(`/warranty/registered/byAgent/campaignList`, {
      params: { registeredTime: registeredTime, sku: sku, productType: productType },
    });
    return result.data.campaignList;
  } catch (err: any) {
    return undefined;
  }
};
interface IRegisterWarranty {
  serialNumber: string;
  customerID: string;
  registeredChannelID: string;
  roomName?: string;
  campaignID?: string;
  registeredAt: number;
  slipImage?: { contentType: string; content: string };
}
const registerWarranty = async ({
  serialNumber,
  customerID,
  registeredChannelID,
  roomName = undefined,
  campaignID = undefined,
  registeredAt,
  slipImage = undefined,
}: IRegisterWarranty) => {
  try {
    const result = await instance.post(`/warranty/registered/byAgent`, {
      serialNumber,
      customerID,
      registeredChannelID,
      roomName,
      campaignID,
      registeredAt,
      slipImage,
    });
    return result.data.campaignList;
  } catch (err: any) {
    return undefined;
  }
};
interface IGetProductType {
  serialNumber: string;
  sku: string;
}
const getProductType = async ({ serialNumber, sku }: IGetProductType) => {
  try {
    const result = await instance.get('/warranty/registered/byAgent/getProductType', {
      params: { serialNumber, sku },
    });
    return result.data.data.type;
  } catch (err: any) {
    return undefined;
  }
};
interface IEditWarranty {
  serialNumber: string;
  campaignID?: string;
  slipImage:
    | {
        contentType: string;
        content: string;
      }
    | undefined;
}
const editWarranty = async ({ serialNumber, campaignID, slipImage }: IEditWarranty) => {
  try {
    const result = await instance.patch(`/warranty/registered/byAgent/${serialNumber}`, {
      campaignID,
      slipImage,
    });
    return result.data.data.type;
  } catch (err: any) {
    return undefined;
  }
};

const deleteSerialNumber = async (serialNumber: string) => {
  try {
    const result = await instance.delete(`/warranty/registered/byAgent/${serialNumber}`);
    return 'success';
  } catch (error) {
    return undefined;
  }
};
const apiWarrantyRegistration = {
  getList,
  attachSlip,
  changeStatus,
  checkVerifySerialNumber,
  calculateSerialNumberExpiryDate,
  getAvailableCampaign,
  registerWarranty,
  getProductType,
  editWarranty,
  deleteSerialNumber,
};
export default apiWarrantyRegistration;

import pageStore from '../contexts/PageStore';
import { IPermissionData } from '../interfaces/permission.interface';
import { instance } from './instance';

const getPermissionList = async () => {
  try {
    const result = await instance.get(`/permission`);
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
const updatePermissionList = async (permissionList: IPermissionData[]) => {
  try {
    const result = await instance.patch(`/permission`, permissionList);
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
const getMenuAvailable = async () => {
  const result = await instance.get(`/permission/agentMenuList`);
  await pageStore.getState().setMenuList(result.data.availableMenuList);
  return result.data;
};
const apiPermission = {
  getPermissionList,
  updatePermissionList,
  getMenuAvailable,
};
export default apiPermission;

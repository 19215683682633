import axios from 'axios';
import { tokenAuthenticationStore } from '../contexts/UserAuthenticationStore';
import loadingStore from '../contexts/LoadingStore';

const baseUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
// const frontendUsername = process.env.REACT_APP_FRONTEND_USERNAME || "";
// const frontendPassword = process.env.REACT_APP_FRONTEND_PASSWORD || "";
// const basicAuth = "Basic " + btoa(`${frontendUsername}:${frontendPassword}`);
const instance = axios.create({
  baseURL: baseUrl,
});

const getNewToken = async () => {
  try {
    const response = await axios.post(
      `${baseUrl}/user/auth/refresh`,
      {},
      {
        headers: {
          Authorization: tokenAuthenticationStore.getState().refreshToken,
        },
      },
    );
    await tokenAuthenticationStore
      .getState()
      .handleLogin(response.data.token, response.data.refreshToken);
    return response.data; // Assuming the new token is returned in this format
  } catch (err) {
    return { token: undefined, refreshToken: undefined };
  }
};
const retryRequest = async (error: any) => {
  const { config } = error;

  // Adjust retry limit as needed
  console.log(`Attempting to refresh token and retry request to ${config.url}`);

  try {
    const newToken = await getNewToken(); // Obtain new token
    config.headers['Authorization'] = `${newToken.token}`; // Set new token on retry request

    return instance(config); // Retry the original request with the new token
  } catch (tokenRefreshError) {
    console.error('Error refreshing token:', tokenRefreshError);
    return Promise.reject(tokenRefreshError); // If token refresh fails, reject with the new error
  }
};
instance.interceptors.request.use((request) => {
  loadingStore.getState().turnOnLoading();
  request.headers.Authorization = `${tokenAuthenticationStore.getState().token}`;
  return request;
});
instance.interceptors.response.use(
  (response) => {
    loadingStore.getState().turnOffLoading();
    return response;
  },
  (error: any) => {
    loadingStore.getState().turnOffLoading();
    if (error.response && error.response.status === 403) {
      return retryRequest(error);
    }

    return Promise.reject(error);
  },
);
export { instance, getNewToken };

export interface IChatUser {
  id: string;
  name: string;
  image: string;
  timestamp: string;
  messages: string[];
}
interface ICustomerSocialChannel {
  id: number;
  customerId: string;
  channel: string;
  subChannel: string;
  displayName: string;
  imageUrl: string;
  customer_user: {
    fullName: string;
    age: number;
    customerId: string;
    firstName: string;
    lastName: string;
    lineUid: string;
    gender: 'MALE' | 'FEMALE' | 'OTHER';
    mobileNumber: string;
    email: string;
    dob: string; // or Date if you want to parse it
    isAcceptConsent: boolean;
    postCode: string;
    regionId: number;
    provinceId: number;
    districtId: number;
    subDistrictId: number;
    createdBy: string;
    createdChannelId: string;
    tags: string[] | null;
    updatedBy: string;
    updatedChannelId: string;
  };
}
export interface ISession {
  id: string;
  assignAgentId: null | string;
  tags: string[];
  startAt: string;
  endAt: string | null;
  status: string;
  lastViewAt: string;
  customer_social_channel: ICustomerSocialChannel;
}
export interface IChatListSession {
  session: ISession;
  latestMessage: {
    id: number;
    sessionId: string;
    senderFrom: string;
    content: string;
    createdAt: string;
    updatedAt: string;
  };
  chatSession?: IChatSession[] | [];
  newMessageCount: number;
}
export interface IPagination {
  totalRecords: number;
  totalPages: number;
  currentPage: number;
  currentPageSize: string;
}
export interface IChatList {
  chatSessions: IChatListSession[];
  pagination: IPagination;
}

export enum ChatMessageType {
  TEXT = 'text',
  IMAGE = 'image',
  // FILE = 'file',
  // VIDEO = 'video',
  // AUDIO = 'audio',
  // LOCATION = 'location',
}
export interface IContentMessage {
  senderFrom: string;
  content: string;
  sessionId: string;
  messageType: ChatMessageType;
  createdAt?: string;
}
interface IRegion {
  id: number;
  code: string;
  nameTh: string;
  nameEn: string | null;
}

interface IProvince {
  id: number;
  regionId: number;
  code: string;
  nameTh: string;
  nameEn: string | null;
}

interface IDistrict {
  id: number;
  provinceId: number;
  code: string;
  nameTh: string;
  nameEn: string | null;
}

interface ISubdistrict {
  id: number;
  districtId: number;
  code: string;
  nameTh: string;
  nameEn: string | null;
  postCode: string;
}

interface ILocation {
  region: IRegion;
  province: IProvince;
  district: IDistrict;
  subdistrict: ISubdistrict;
}
export interface ICustomerDetail {
  assignAgentId: string | null;
  customer_social_channel: ICustomerSocialChannel;
  id: string;
  lastViewAt: string;
  startAt: string;
  status: string;
  tags: string[];
  agent?: IAgent;
  customer_address_data: ILocation;
}
interface IAgent {
  fullName: string;
  id: string;
  firstName: string;
  lastName: string;
}
export interface IChatSession {
  pagination: {
    totalMessage: number;
    totalPages: number;
    currentPage: string;
    currentPageSize: string;
  };
  contents: {
    customerDetails: ICustomerDetail;
    messages: IContentMessage[];
  };
}

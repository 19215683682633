import { Modal } from 'antd';
import Layout from '../Layout';

import styled from 'styled-components';

import { useEffect, useState } from 'react';

import List from './List';
import Create from './Create';
import Edit from './Edit';
import { IRegistrationRecord } from '../../../../interfaces/warrantyRegistration.interface';
import { useLocation } from 'react-router-dom';
const WarrantyRegistration = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [contentState, setContentState] = useState<'table' | 'create' | 'edit'>('table');
  const [selectedRecord, setSelectedRecord] = useState<IRegistrationRecord | undefined>(undefined);

  const renderContent = () => {
    switch (contentState) {
      case 'table':
        return (
          <List
            setContentState={setContentState}
            modal={modal}
            setSelectedRecord={setSelectedRecord}
          />
        );
      case 'create':
        return <Create setContentState={setContentState} modal={modal} />;
      case 'edit':
        if (selectedRecord) {
          return (
            <Edit setContentState={setContentState} modal={modal} selectedRecord={selectedRecord} />
          );
        } else {
          return <></>;
        }
    }
  };
  return (
    <Layout>
      <SContainer>
        {renderContent()}
        {contextHolder}
      </SContainer>
    </Layout>
  );
};
export default WarrantyRegistration;

const SContainer = styled.div`
  height: 95% !important;
  .custom-table .ant-table-cell {
    font-size: 14px;
  }
  .no {
    color: #1890ff;
  }
  .customer {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      width: 30px;
      height: 30px;
      overflow: hidden;
      background: grey;
      border-radius: 50px;
      margin-right: 5px;
      & > img {
        height: 100%;
      }
    }
  }
  .slip {
    cursor: pointer;
  }
  .channel {
    border: 1px solid;
    border-radius: 100px;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    & > div:nth-child(1) {
      margin-right: 2px;
    }
    &.line {
      border-color: #06c755;
      background-color: #dcfae6;
    }
    &.facebook_messenger {
      border-color: #0866ff;
      background-color: #e6f0ff;
    }
    &.widget {
      border-color: #f79009;
      background-color: #fffaeb;
    }
  }
  .hashtag {
    width: fit-content;
    border-radius: 100px;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdeaf2;
    color: #ed2e7b;
  }
  .status {
    border-radius: 100px;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    &.approved {
      background-color: #e2ffe9;
      color: #17b26a;
    }
    &.rejected {
      background-color: #fef3f2;
      color: #f04438;
    }
    &.pending {
      background-color: #fffaeb;
      color: #f79009;
    }
    &.inCompleted {
      background-color: #eaecf0;
      color: #737373;
    }
  }
  .option {
    cursor: pointer;
    position: relative;
  }
`;

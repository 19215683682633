import styled from 'styled-components';
import Menu from '../../components/home/Menu';
import Content from '../../components/home/Content';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userAuthenticationStore } from '../../contexts/UserAuthenticationStore';

import loadingStore from '../../contexts/LoadingStore';
import Loading from '../../components/Loading';
import useSocketStore from '../../contexts/SocketStore';
import chatStore from '../../contexts/ChatStore';
import pageStore from '../../contexts/PageStore';
import apiPermission from '../../apis/permission';

const Home = () => {
  const navigate = useNavigate();
  const { isLoading } = loadingStore();
  const { contentSelected } = pageStore();
  const tags = userAuthenticationStore((state) => state.tags);
  const adminId = userAuthenticationStore((state) => state.id);
  const { resetAllChatList } = chatStore();
  useEffect(() => {
    resetAllChatList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentSelected]);
  const user = userAuthenticationStore((state) => state);
  const getConstantContent = async () => {
    await apiPermission.getMenuAvailable();
  };
  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
    } else {
      getConstantContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);
  const { initializeSocket } = useSocketStore();
  const url = `${process.env.REACT_APP_BACKEND_URL}?userId=${adminId}&tags=${tags.join(',')}`;
  useEffect(() => {
    initializeSocket(url);
  }, [initializeSocket, url]);
  return (
    <SContainer>
      {isLoading && <Loading />}
      <Menu />
      <Content />
    </SContainer>
  );
};
export default Home;
const SContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  overflow: hidden;
  position: relative;
  .react-resizable-handle {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: col-resize;
    width: 10px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

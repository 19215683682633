import chatStore from '../contexts/ChatStore';
import manageAgentStore from '../contexts/ManageAgentStore';
import { instance } from './instance';
import { CreateAgentParameters, UpdateAgentParameters } from '../interfaces/agent.interface';

interface IGetAgentList {
  page: number;
  pageSize: string;
  q: string;
}

const getAgentList = async ({ page, pageSize, q }: IGetAgentList) => {
  try {
    const result = await instance.get(`/agent`, {
      params: { page: page, pageSize: pageSize, q: q },
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};

const registerNewAgent = async (params: CreateAgentParameters) => {
  try {
    const result = await instance.post(`/agent`, params);
    return result.data;
  } catch (error: any) {
    return error;
  }
};

const updateAgentByID = async (params: UpdateAgentParameters, ID: string) => {
  try {
    const result = await instance.patch(`/agent/process/${ID}`, params);
    return result.data;
  } catch (error: any) {
    return error;
  }
};

const getAgentConstantContent = async () => {
  try {
    const result = await instance.get(`/agent/createUpdateOptions`);
    manageAgentStore.getState().setAgentConstantContent(result.data);
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface IGetQuickReply {
  q: string;
  page?: string;
  pageSize?: string;
}
const getQuickReply = async ({ q, page, pageSize }: IGetQuickReply) => {
  try {
    const result = await instance.get(`/agent/quickReply`, { params: { q: q } });
    chatStore.getState().setQuickReplyList(result.data.quickReplies);
    chatStore.getState().setTotalQuickReply(result.data.total);
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface ICreateQuickReply {
  abbrMsg: string;
  fullMsg: string;
  departments: number[];
}
const createQuickReply = async (newQuickReply: ICreateQuickReply) => {
  try {
    const result = await instance.post(`/agent/quickReply`, { ...newQuickReply });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface IEditQuickReply {
  id: number;
  data: { abbrMsg: string; fullMsg: string; departments: number[] };
}
const editQuickReply = async ({ data, id }: IEditQuickReply) => {
  try {
    const result = await instance.patch(`/agent/quickReply/${id}`, { ...data });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
const deleteQuickReply = async (id: number) => {
  try {
    const result = await instance.delete(`/agent/quickReply/${id}`);
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
const deleteAgent = async (id: string) => {
  try {
    const result = await instance.delete(`/agent/process/${id}`);
    return true;
  } catch (err: any) {
    return false;
  }
};
const apiAgent = {
  getAgentList,
  registerNewAgent,
  updateAgentByID,
  getAgentConstantContent,
  getQuickReply,
  createQuickReply,
  editQuickReply,
  deleteQuickReply,
  deleteAgent,
};
export default apiAgent;

import styled from 'styled-components';
import Layout from './Layout';
import { Form, Input } from 'antd';
import { ReactComponent as Information } from '../../../assets/home/information.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { userAuthenticationStore } from '../../../contexts/UserAuthenticationStore';
import BaseButtonDialog from '../../dialogs/BaseButtonDialog';
import adminImage from '../../../assets/admin.png';
import pageStore from '../../../contexts/PageStore';

const Profile = () => {
  const user = userAuthenticationStore((state) => state);
  const { setContentSelected } = pageStore();

  return (
    <Layout>
      <SContainer>
        <div
          style={{ cursor: 'pointer', color: '#737373', fontSize: '14px' }}
          onClick={() => {
            setContentSelected('default');
          }}
        >
          <ArrowLeftOutlined />
          Back
        </div>
        <div
          style={{
            fontSize: '20px',
            fontWeight: '700',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          Profile
        </div>
        <SContent>
          <SDisplayContainer>
            <div className="display">
              <SDisplay>
                <img src={adminImage} alt="userDisplay" />
              </SDisplay>
              <div style={{ fontSize: '32px', fontWeight: '500' }}>{user.fullName}</div>
            </div>
            <div
              style={{
                marginTop: '15px',
                marginBottom: '15px',
                fontWeight: '700',
              }}
            >
              General Information
            </div>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
              initialValues={{ remember: true }}
              onFinish={() => {}}
              layout="vertical"
            >
              <Form.Item<string> label="First name" name="firstName">
                <Input placeholder={user.firstName} disabled />
              </Form.Item>
              <Form.Item<string> label="Last name" name="lastName">
                <Input placeholder={user.lastName} disabled />
              </Form.Item>
              <Form.Item<string> label="Phone number" name="phoneNumber">
                <Input placeholder={'-'} disabled />
              </Form.Item>
              <Form.Item<string> label="Email" name="email">
                <Input placeholder={user.email} disabled />
              </Form.Item>
              <Form.Item<string> label="Password" name="password">
                <Input.Password placeholder="***********" disabled />
              </Form.Item>
            </Form>
            <BaseButtonDialog dialog="changePassword" />
          </SDisplayContainer>
          <SInformation>
            <div className="detail">
              <div style={{ fontSize: '20px', fontWeight: '500' }}>Profile</div>
              <div style={{ marginTop: '10px', lineHeight: '25px' }}>
                As an administrator, utilize the Edit Profile function to efficiently and swiftly
                manage user accounts. This feature enables you to modify information, oversee roles
                and permissions, and monitor changes with an audit trail.
              </div>
            </div>
            <div>
              <Information />
            </div>
          </SInformation>
        </SContent>
      </SContainer>
    </Layout>
  );
};
export default Profile;
const SInformation = styled.div`
  width: 100%;
  background-color: #f2f4f7;
  height: fit-content;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  & > div {
    width: 50%;
  }
  & > div {
    &.detail {
      padding-left: 30px;
    }
  }
`;
const SContainer = styled.div``;
const SDisplayContainer = styled.div`
  & > div {
    &.display {
      display: flex;
      align-items: center;
    }
  }
`;
const SContent = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
  }
`;
const SDisplay = styled.div`
  height: 8rem;
  width: 8rem;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background-color: grey;
  margin-right: 10px;
  display: flex;
  & > img {
    height: 100%;
  }
`;

import styled from 'styled-components';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import { Modal, Table, Tooltip } from 'antd';
import { useQuery } from '@tanstack/react-query';
import apiAgent from '../../../../apis/agent';
import { ColumnsType } from 'antd/es/table';
import { IAgent } from '../../../../interfaces/agent.interface';
import { ReactComponent as Edit } from '../../../../assets/edit.svg';
import { ReactComponent as Delete } from '../../../../assets/bin.svg';
import { ReactComponent as Create } from '../../../../assets/union.svg';
import ResizableTitle from '../../../ResizeableTitle';
interface IList {
  setContentState: React.Dispatch<React.SetStateAction<'table' | 'create' | 'update'>>;
  setSelectedRecord: React.Dispatch<React.SetStateAction<IAgent | undefined>>;
}

const PAGE_SIZE = 5;
const List = ({ setContentState, setSelectedRecord }: IList) => {
  const [modal, contextHolder] = Modal.useModal();
  const [searchValue, setSearchValue] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(1);
  const [dataSource, setDataSource] = useState<IAgent[]>([]);
  const handleTyping = (value: string) => {
    setSearchValue(value);
    setPageNo(1);
  };

  const {
    data: agentListData,
    error: agentListError,
    isLoading: agentListIsLoading,
    isFetching: agentListIsFetching,
  } = useQuery({
    queryKey: ['allAgent', pageNo, searchValue],
    queryFn: async () => {
      return apiAgent.getAgentList({
        page: pageNo,
        pageSize: `${PAGE_SIZE}`,
        q: searchValue,
      });
    },
  });
  const handleConvertData = async () => {
    if (agentListData?.data) {
      const result: IAgent[] = [];
      for (let i = 0; i < agentListData.data.length; i++) {
        const agentData: IAgent = agentListData.data[i];
        result.push({
          id: agentData.id,
          firstName: agentData.firstName,
          lastName: agentData.lastName,
          email: agentData.email,
          phoneNumber: agentData.phoneNumber,
          channel: agentData.channel,
          channelID: agentData.channelID,
          language: agentData.language,
          languageID: agentData.languageID,
          department: agentData.department,
          departmentID: agentData.departmentID,
          role: agentData.role,
          roleID: agentData.roleID,
          tags: agentData.tags,
        });
      }

      setDataSource(result);
    } else {
      setDataSource([]);
    }
  };
  useEffect(() => {
    handleConvertData();
  }, [agentListData?.data]);
  const handleTableChange = (newPagination: number) => {
    setPageNo(newPagination);
  };
  const [columns, setColumns] = useState<ColumnsType<IAgent>>([
    {
      title: 'No',
      key: 'no',
      render: (text, record, index) => (
        <div className="no">{(pageNo - 1) * PAGE_SIZE + index + 1}</div>
      ),
      width: 100,
      ellipsis: true,
      fixed: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 150,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (text, record) => <div>{`${record.firstName} ${record.lastName}`}</div>,
      fixed: 'left',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      width: 200,
      render: (text) => {
        if (text) {
          return <SChannel className="channel">{text}</SChannel>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      width: 200,
      render: (text) => {
        if (text) {
          return <SChannel className="language">{text}</SChannel>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      width: 200,
      render: (text) => {
        if (text) {
          return (
            <Tooltip title={text}>
              <SChannel style={{ whiteSpace: 'nowrap' }} className="department">
                {text.length > 17 ? `${text.slice(0, 17)}...` : text}
              </SChannel>
            </Tooltip>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 200,
      render: (text) => {
        if (text) {
          return <SChannel className="role">{text}</SChannel>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 300,
      render: (text) => (
        <STagsContainer style={{ display: 'flex', flexWrap: 'wrap' }}>
          {text.map((e: string, i: number) => {
            return (
              <Tooltip title={e}>
                <div style={{ whiteSpace: 'nowrap' }}>
                  {e.length > 31 ? `${e.slice(0, 31)}...` : e}
                </div>
              </Tooltip>
            );
          })}
        </STagsContainer>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 50,
      render: (text, record, index) => {
        return (
          <div
            className="option"
            onClick={() => {
              setContentState('update');
              setSelectedRecord(record);
            }}
          >
            <Edit />
          </div>
        );
      },
      fixed: 'right',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 50,
      render: (text, record, index) => {
        const onClick = async () => {
          modal.confirm({
            title: 'Alert',
            content: <>Are you sure you want to delete this user?</>,
            onOk: async () => {
              const deleted = await apiAgent.deleteAgent(record.id);
              if (deleted) {
                const result = [...dataSource];
                const index = result.findIndex((obj) => obj.id === record.id);
                result.splice(index, 1);
                setDataSource(result);
              } else {
                await modal.error({
                  title: 'Error',
                  content: <>Can not delete this user.</>,
                  onOk() {},
                  onCancel() {},
                });
              }
            },
            onCancel() {},
          });
        };
        return (
          <div className="option" onClick={() => onClick()}>
            <Delete />
          </div>
        );
      },
      ellipsis: true,
      fixed: 'right',
    },
  ]);
  const handleResize =
    (index: number) =>
    (e: React.SyntheticEvent, { size }: any) => {
      setColumns((prevColumns) => {
        const newColumns = [...prevColumns];
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
        };
        return newColumns;
      });
    };

  const mergedColumns: any = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));
  return (
    <>
      {contextHolder}
      <SHeader>
        <div>
          <div style={{ fontSize: '20px', fontWeight: 700 }}>
            <div>Agent Management</div>
          </div>
          <div
            style={{ fontSize: '12px', fontWeight: 400 }}
          >{`จำนวนทั้งหมด ${agentListData?.total} รายการ`}</div>
        </div>
        <SContainerButton style={{ display: 'flex' }}>
          <div style={{ width: '70%' }}>
            <Search
              placeholder="Search"
              value={searchValue}
              onChange={(e) => handleTyping(e.target.value)}
              width={'100%'}
            />
          </div>
          <SCreateButton
            style={{ width: '150px' }}
            onClick={() => {
              setContentState('create');
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Create />
            </div>
            <div style={{ marginLeft: '5px' }}>Create agent</div>
          </SCreateButton>
        </SContainerButton>
      </SHeader>
      {agentListData?.total && (
        <Table
          className="custom-table"
          dataSource={dataSource}
          columns={mergedColumns}
          pagination={{
            current: pageNo,
            pageSize: PAGE_SIZE,
            total: agentListData.total,
            showSizeChanger: false,
          }}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          style={{ marginTop: '10px' }}
          rowKey={'serialNumber'}
          scroll={{ x: 2500 }}
          onChange={(e) => e.current && handleTableChange(e.current)}
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        />
      )}
    </>
  );
};

export default List;

const SHeader = styled.div`
  display: grid;
  grid-template: 1fr/2fr 1fr;
`;
const SChannel = styled.div`
  width: fit-content;
  border: 1px solid;
  border-radius: 20px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
  padding: 0px 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  &.channel {
    border-color: #06c755;
    background-color: #dcfae6;
  }
  &.language {
    border-color: #0866ff;
    background-color: #e6f0ff;
  }
  &.department {
    border-color: #f79009;
    background-color: #fffaeb;
  }
  &.role {
    border-color: #9409f7;
    background-color: #fcebff;
  }
`;
const STagsContainer = styled.div`
  & > div {
    border: solid 1px;
    padding: 1px 10px;
    margin: 5px 5px;
    border-radius: 50px;
    &:nth-child(1) {
      border-color: #06c755;
      background-color: #dcfae6;
    }
    &:nth-child(2) {
      border-color: #f79009;
      background-color: #fffaeb;
    }
    &:nth-child(3) {
      border-color: #0866ff;
      background-color: #e6f0ff;
    }
  }
`;
const SCreateButton = styled.div`
  height: 80%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  margin-left: 10px;
  border-radius: 8px;
  color: white;
  background-color: #001689;
  & > div:nth-child(1) {
    margin-right: 5px;
  }
`;
const SContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const timeMessage = (timestamp: string) => {
  let date;
  date = new Date(timestamp);
  if (isNaN(date.getTime())) {
    date = new Date();
  }
  const now = new Date();
  const diffTime = Math.abs(now.getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  let output;
  if (diffDays === 1) {
    output = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  } else if (diffDays > 1) {
    output = `${diffDays - 1} day${diffDays - 1 > 1 ? 's' : ''} ago`;
  }
  return output;
};
export const dateFormatter = (dateString: string) => {
  let date;
  date = new Date(dateString);
  if (isNaN(date.getTime())) {
    date = new Date();
  }

  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(date);

  return formattedDate;
};
export const formatTime = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
  let date;
  date = new Date(dateString);
  if (isNaN(date.getTime())) {
    date = new Date();
  }
  return date.toLocaleTimeString('en-US', options);
};

export const emptyTextFormatter = (text: string, replaceWith = '-') => {
  return text && text.length > 0 ? text : replaceWith;
};

export const customerGenderFormatter = (gender: string) => {
  let returnText = 'อื่นๆ';
  const lowercasedIncomingGender = gender.toLowerCase();
  if (lowercasedIncomingGender === 'male') returnText = 'ชาย';
  else if (lowercasedIncomingGender === 'female') returnText = 'หญิง';

  return returnText;
};

export const emptyPostCodeFormatter = (postCode: string) => {
  return postCode && postCode.length > 0 && postCode !== '0' ? postCode : '-';
};

import { useQuery } from '@tanstack/react-query';
import Search from 'antd/es/input/Search';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ICustomerData } from '../../../../interfaces/customer.interface';
import apiCustomer from '../../../../apis/customer';
import { Checkbox, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  dateFormatter,
  emptyTextFormatter,
  customerGenderFormatter,
  emptyPostCodeFormatter,
} from '../../../../utils/format';
import { ReactComponent as Edit } from '../../../../assets/edit.svg';
import { HookAPI } from 'antd/es/modal/useModal';
import { useLocation } from 'react-router-dom';
import ResizableTitle from '../../../ResizeableTitle';
import defaultAvatar from '../../../../assets/default-avatar.jpg';
const PAGE_SIZE = 9;
interface IList {
  setContentState: React.Dispatch<React.SetStateAction<'table' | 'edit'>>;
  modal: HookAPI;
  setSelectedRecord: React.Dispatch<React.SetStateAction<ICustomerData | undefined>>;
}
const List = ({ setContentState, modal, setSelectedRecord }: IList) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(1);
  // const [dataSource, setDataSource] = useState<ICustomerData[]>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const phone = queryParams.get('phone');

  const handleTyping = (value: string) => {
    setSearchValue(value);
    setPageNo(1);
  };
  const {
    data: customerListData,
    error: customerListError,
    isLoading: customerListIsLoading,
    isFetching: customerListIsFetching,
  } = useQuery<{ records: ICustomerData[]; total: number }>({
    queryKey: ['allChatList', searchValue, pageNo],
    queryFn: async () => {
      return apiCustomer.getCustomerList({
        q: searchValue,
        page: `${pageNo}`,
        pageSize: `${PAGE_SIZE}`,
      });
    },
  });

  const dataSource = useMemo<ICustomerData[]>(() => {
    if (!customerListData?.records) return [];
    return customerListData?.records;
  }, [customerListData]);
  useEffect(() => {
    if (phone) {
      setSearchValue(phone);
    }
  }, [phone]);
  useEffect(() => {
    if (phone) {
      const result = dataSource.filter((e) => e.customerId === id);
      if (!result.length) return;
      setSelectedRecord(result[0]);
      setContentState('edit');
    }
  }, [dataSource]);
  const handleTableChange = (newPagination: number) => {
    setPageNo(newPagination);
  };
  const [columns, setColumns] = useState<ColumnsType<ICustomerData>>([
    {
      title: 'Customer',
      key: 'customer',
      render: (text, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              marginRight: '5px',
              overflow: 'hidden',
              borderRadius: '50px',
            }}
          >
            <img
              style={{ width: '20px', height: '20px' }}
              alt="profile"
              src={record?.customer_social_channels?.[0]?.imageUrl || defaultAvatar}
            />
          </div>
          <div style={{ width: '20px' }}>{`${record.firstName} ${record.lastName}`}</div>
        </div>
      ),
      width: 270,
      ellipsis: true,
    },
    {
      title: 'Phone number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      width: 260,
      render: (text) => <div>{emptyTextFormatter(text)}</div>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 260,
      render: (text) => <div>{emptyTextFormatter(text, 'ไม่มีอีเมล')}</div>,
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
      width: 260,
      render: (text) => <div>{dateFormatter(text)}</div>,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      width: 230,
      render: (text) => <div>{customerGenderFormatter(text)}</div>,
    },
    {
      title: 'Consent',
      dataIndex: 'isAcceptConsent',
      key: 'isAcceptConsent',
      width: 220,
      render: (text) => <Checkbox defaultChecked={text} disabled />,
    },
    {
      title: 'Region',
      dataIndex: 'regionId',
      key: 'regionId',
      width: 230,
      render: (text, record) => <div>{record.customer_region_data.nameTh}</div>,
    },
    {
      title: 'Province',
      dataIndex: 'provinceId',
      key: 'provinceId',
      width: 240,
      render: (text, record) => <div>{record.customer_province_data.nameTh}</div>,
    },
    {
      title: 'District',
      dataIndex: 'districtId',
      key: 'districtId',
      width: 250,
      render: (text, record) => <div>{record.customer_district_data.nameTh}</div>,
    },
    {
      title: 'Subdistrict',
      dataIndex: 'subDistrictId',
      key: 'subDistrictId',
      width: 250,
      render: (text, record) => <div>{record.customer_subdistrict_data.nameTh}</div>,
    },
    {
      title: 'Postcode',
      dataIndex: 'postCode',
      key: 'postCode',
      width: 230,
      render: (text, record) => (
        <div>{emptyPostCodeFormatter(record.customer_subdistrict_data.postCode)}</div>
      ),
    },
    {
      title: 'Create date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 260,
      render: (text) => <div>{dateFormatter(text)}</div>,
    },
    {
      title: 'Updated date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 260,
      render: (text) => <div>{dateFormatter(text)}</div>,
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 100,
      render: (text, record, index) => {
        const onClick = () => {
          setSelectedRecord(record);
          setContentState('edit');
        };
        return (
          <div className="option" onClick={() => onClick()}>
            <Edit />
          </div>
        );
      },
      ellipsis: true,
      fixed: 'right',
    },
  ]);
  const handleResize =
    (index: number) =>
    (e: React.SyntheticEvent, { size }: any) => {
      setColumns((prevColumns) => {
        const newColumns = [...prevColumns];
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
        };
        return newColumns;
      });
    };

  const mergedColumns: any = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));
  return (
    <>
      <SHeader>
        <div>
          <div style={{ fontSize: '20px', fontWeight: 700 }}>Customer</div>
          <div
            style={{ fontSize: '12px', fontWeight: 400 }}
          >{`จำนวนทั้งหมด ${customerListData?.total} รายการ`}</div>
        </div>
        <div>
          <div>
            <Search
              placeholder="Search"
              value={searchValue}
              onChange={(e) => handleTyping(e.target.value)}
            />
          </div>
        </div>
      </SHeader>
      {customerListData?.total && (
        <Table
          className="custom-table"
          dataSource={dataSource}
          columns={mergedColumns}
          pagination={{
            current: pageNo,
            pageSize: PAGE_SIZE,
            total: customerListData.total,
            showSizeChanger: false,
          }}
          rowKey={'serialNumber'}
          scroll={{ x: 2000 }}
          onChange={(e) => e.current && handleTableChange(e.current)}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        />
      )}
    </>
  );
};
export default List;
const SHeader = styled.div`
  display: grid;
  grid-template: 1fr/2fr 1fr;
`;

import { Button, DatePicker, DatePickerProps, Input, message, Select, Upload } from 'antd';
import Search from 'antd/es/input/Search';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import { ReactComponent as CreateButton } from '../../../../assets/create.svg';
import { ReactComponent as Arrow } from '../../../../assets/arrow.svg';
import { ReactComponent as ImageIcon } from '../../../../assets/imageIcon.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/infoIcon.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/warningIcon.svg';
import { HookAPI } from 'antd/es/modal/useModal';
import { useEffect, useRef, useState } from 'react';
import apiCustomer from '../../../../apis/customer';
import { ICustomerData } from '../../../../interfaces/customer.interface';
import apiWarrantyRegistration from '../../../../apis/warrantyRegistration';
import {
  ICampaign,
  ISerialNumberType,
} from '../../../../interfaces/warrantyRegistration.interface';
import dayjs from 'dayjs';
import imageCompression from 'browser-image-compression';
import loadingStore from '../../../../contexts/LoadingStore';
import { useLocation, useNavigate } from 'react-router-dom';
interface ICreate {
  setContentState: React.Dispatch<React.SetStateAction<'table' | 'create' | 'edit'>>;
  modal: HookAPI;
}
const { Option } = Select;

const Create = ({ setContentState, modal }: ICreate) => {
  const { turnOffLoading } = loadingStore();
  const [searchCustomer, setSearchCustomer] = useState('');
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [checkSerialNumberLoading, setCheckSerialNumberLoading] = useState(false);
  const [serialNumberVerify, setSerialNumberVerify] = useState(false);
  const [verifyErrorMessage, setVerifyErrorMessage] = useState('');
  const [expiredDate, setExpiredDate] = useState<number>(0);
  const [registerDate, setRegisterDate] = useState<number>(0);
  const [serialNumberPassVerify, setSerialNumberPassVerify] = useState<
    ISerialNumberType | undefined
  >(undefined);
  const [campaignList, setCampaignList] = useState<ICampaign[] | []>([]);
  const [campaignSelected, setCampaignSelected] = useState<ICampaign | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState('');
  const [customerSelect, setCustomerSelect] = useState<ICustomerData | undefined>(undefined);
  const [channelTypeSelect, setChannelTypeSelect] = useState<string | undefined>(undefined);
  const [slipBase64, setSlipBase64] = useState<{ contentType: string; content: string } | null>(
    null,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const getBase64 = (file: File, callback: (result: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result as string);
    reader.onerror = (error) => {
      message.error('File could not be read');
      console.error('Error: ', error);
    };
  };
  useEffect(() => {
    if (id) {
      setSearchCustomer(id);
    }
  }, [id]);
  const handleUpload = async (file: File) => {
    const compressedFile = await compressImage(file);
    getBase64(compressedFile, (result) => {
      const base64Result = result.split('base64,');
      setImageUrl(result);
      setSlipBase64({ contentType: file.type, content: base64Result[1] });
    });
    return false; // Prevent default upload behavior
  };
  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Image compression failed:', error);
      return file;
    }
  };
  const [customerList, setCustomerList] = useState<ICustomerData[]>([]);
  const searchIntervalRef = useRef<NodeJS.Timer>();
  const handleGetCustomerList = async () => {
    clearInterval(searchIntervalRef.current);
    searchIntervalRef.current = setInterval(async () => {
      const result = await apiCustomer.getCustomerList({
        q: searchCustomer,
        page: '1',
        pageSize: '5',
      });
      setCustomerList(result.records);
      clearInterval(searchIntervalRef.current);
    }, 700);
  };

  useEffect(() => {
    if (id) {
      turnOffLoading();
      handleCustomerSelect(id);
    }
  }, [customerList]);
  useEffect(() => {
    handleGetCustomerList();
  }, [searchCustomer]);
  const handleCustomerSelect = (value: string) => {
    const result = customerList.filter((e) => e.customerId === value);
    setCustomerSelect(result[0]);
  };
  const handleChannelTypeSelect = (value: string) => {
    setChannelTypeSelect(value);
  };
  const handleCheckSerialNumber = async () => {
    if (!serialNumber) return;
    setCheckSerialNumberLoading(true);
    const result = await apiWarrantyRegistration.checkVerifySerialNumber(serialNumber);
    setSerialNumberVerify(true);
    if (result) {
      setSerialNumberPassVerify(result);
      setVerifyErrorMessage('');
    } else {
      setVerifyErrorMessage('Serial number not found. Please check and try again.');
    }

    setCheckSerialNumberLoading(false);
  };
  const onDatePickerChange: DatePickerProps['onChange'] = async (date, dateString) => {
    setCampaignSelected(undefined);
    const unix = date?.valueOf();

    if (!unix || !serialNumberPassVerify) return;
    setRegisterDate(unix);
    const expiredResult = await apiWarrantyRegistration.calculateSerialNumberExpiryDate(
      serialNumberPassVerify.product.serial_number,
      unix,
    );
    setExpiredDate(expiredResult.expiredTime);
    const campaignListResult = await apiWarrantyRegistration.getAvailableCampaign({
      registeredTime: unix,
      sku: serialNumberPassVerify?.product.product_code,
      productType: serialNumberPassVerify?.type,
    });
    setCampaignList(campaignListResult);
  };
  const handleCampaignSelect = (value: string) => {
    if (value === '-') {
      setCampaignSelected(undefined);
    } else {
      const result = campaignList.filter((e) => e.campaignID === value);
      setCampaignSelected(result[0]);
    }
  };
  const handleCreateWarranty = async () => {
    if (!serialNumberPassVerify?.product || !customerSelect?.customerId || !channelTypeSelect)
      return;
    await apiWarrantyRegistration.registerWarranty({
      serialNumber: serialNumberPassVerify?.product.serial_number,
      customerID: customerSelect?.customerId,
      registeredChannelID: channelTypeSelect,
      roomName: undefined,
      campaignID: campaignSelected?.campaignID || '',
      registeredAt: registerDate,
      slipImage: slipBase64 || undefined,
    });
    handleClose();
    setContentState('table');
  };
  const handleClose = () => {
    setSearchCustomer('');
    setSerialNumber('');
    setCheckSerialNumberLoading(false);
    setSerialNumberVerify(false);
    setExpiredDate(0);
    setRegisterDate(0);
    setSerialNumberPassVerify(undefined);
    setCampaignList([]);
    setCampaignSelected(undefined);
    setImageUrl('');
    setCustomerSelect(undefined);
    setChannelTypeSelect(undefined);
    setSlipBase64(null);
    setCustomerList([]);
  };
  const handleCampaignOption = () => {
    const result = [{ value: '-', label: '-' }];
    if (campaignList?.length) {
      campaignList.forEach((e) => {
        result.push({ value: e.campaignID, label: e.name });
      });
    }
    return result;
  };
  return (
    <SContainer>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          modal.confirm({
            title: <div style={{ marginLeft: '5px' }}>Leave page?</div>,
            content: <>Any unsaved changes will be lost.</>,
            okText: 'Leave',
            cancelText: 'Keep editing',
            icon: <WarningIcon />,
            okButtonProps: {
              style: {
                backgroundColor: '#ff4d4f',
                borderColor: '#ff4d4f',
                color: '#fff',
              },
            },
            onOk() {
              handleClose();
              setContentState('table');
              navigate('/home/warranty_register');
            },
            onCancel() {},
          });
        }}
      >
        <div>
          <Arrow fill="#737373" />
        </div>
        <div>Back</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '10px', display: 'flex' }}>
          <CreateButton />
        </div>
        <div>Create a warranty</div>
      </div>
      <div>Warranty Information</div>
      <div>
        <div>
          <div>Customer</div>
          <div>
            <Select
              showSearch
              style={{ width: '100%', height: '60px' }}
              placeholder="Select a customer"
              optionFilterProp="children"
              onChange={handleCustomerSelect}
              value={customerSelect?.customerId || id}
              filterOption={false}
              onSearch={(e) => {
                setSearchCustomer(e);
              }}
            >
              {customerList.map((customer) => (
                <Option
                  key={customer.customerId}
                  value={customer.customerId}
                  style={{
                    fontSize: '14px',
                    padding: '10px',
                    backgroundColor:
                      customerSelect?.customerId === customer.customerId ? '#f5f5f5' : '#FFFF',
                    // borderBottom: '1px solid #d9d9d9',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', lineHeight: '20px' }}>
                    <img
                      alt="usdp"
                      src={customer?.customer_social_channels?.[0]?.imageUrl}
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50px',
                        marginRight: '5px',
                      }}
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        {customer.firstName} {customer.lastName}
                      </div>
                      <div style={{ fontSize: '10px', fontWeight: 'lighter' }}>
                        {customer.mobileNumber}
                      </div>
                    </div>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '30px' }}>
        <div>
          <div>Channel type</div>
          <div>
            <Select
              onChange={handleChannelTypeSelect}
              disabled={customerSelect ? false : true}
              placeholder="Select a person"
              style={{ width: '100%' }}
              options={customerSelect?.customer_social_channels.map((e) => {
                return { value: e.socialChannelId, label: `${e.channel} ${e.subChannel}` };
              })}
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>Serial number</div>
          <div>
            <Search
              status={!serialNumberVerify ? '' : serialNumberPassVerify ? '' : 'error'}
              placeholder="Enter serial number"
              disabled={channelTypeSelect ? false : true}
              enterButton={
                <Button
                  disabled={serialNumberVerify ? true : false}
                  style={{
                    backgroundColor: !serialNumberVerify
                      ? '#1677FF'
                      : serialNumberPassVerify
                      ? '#EFF8F3'
                      : '#FEF3F2',
                    color: !serialNumberVerify
                      ? 'white'
                      : serialNumberPassVerify
                      ? '#1D9F61'
                      : '#FF4D4F',
                  }}
                >
                  {serialNumberVerify ? 'Verified' : 'Verify'}
                </Button>
              }
              value={serialNumber}
              onChange={(e) => {
                setSerialNumberVerify(false);
                setSerialNumber(e.target.value);
                setExpiredDate(0);
                setRegisterDate(0);
                setSerialNumberPassVerify(undefined);
                setCampaignSelected(undefined);
              }}
              onSearch={() => handleCheckSerialNumber()}
              size="large"
              loading={checkSerialNumberLoading}
            />
            {!serialNumberPassVerify && serialNumberVerify && (
              <div style={{ fontSize: '10px', color: '#FF4D4F' }}>{verifyErrorMessage}</div>
            )}
          </div>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <div>Product name</div>
            <div style={{ marginLeft: '5px', color: '#BFBFBF' }}>
              (It will display when the serial number is checked.)
            </div>
          </div>
          <div>
            <Input
              placeholder={
                serialNumberPassVerify ? serialNumberPassVerify.product.product_name : '-'
              }
              disabled
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>Registration date</div>
          <div>
            <DatePicker
              value={registerDate > 0 ? dayjs(registerDate) : null}
              onChange={onDatePickerChange}
              style={{ width: '100%' }}
              disabled={channelTypeSelect ? false : true}
            />
          </div>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <div>Expired date</div>
            <div style={{ marginLeft: '5px', color: '#BFBFBF' }}>
              (It will display when the registration date is picked.)
            </div>
          </div>
          <div>
            <DatePicker
              placeholder="-"
              value={expiredDate > 0 ? dayjs(expiredDate) : null}
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>Campaign hashtag</div>
          <div>
            <Select
              showSearch
              placeholder="Select a Campaign"
              style={{ width: '100%' }}
              value={campaignSelected?.campaignID || '-'}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={handleCampaignSelect}
              disabled={channelTypeSelect ? false : true}
              options={handleCampaignOption()}
            />
          </div>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '5px' }}>Slip</div>
          <div style={{ color: '#BFBFBF' }}>(Optional)</div>
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            height: '100px',
          }}
        >
          <SImageBox>
            {imageUrl ? (
              <img src={imageUrl} alt="uploaded" />
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <ImageIcon />
                </div>
                <div>image</div>
              </div>
            )}
          </SImageBox>
          <Upload beforeUpload={handleUpload} showUploadList={false}>
            <Button icon={<UploadOutlined />} disabled={channelTypeSelect ? false : true}>
              Upload
            </Button>
            <div style={{ color: '#BFBFBF' }}>Upload .png or .jpeg files up to 10MB</div>
          </Upload>
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <Button
          style={{ width: '15%', marginRight: '20px', height: '50px' }}
          onClick={() => {
            modal.confirm({
              title: <div style={{ marginLeft: '5px' }}>Leave page?</div>,
              content: <>Any unsaved changes will be lost.</>,
              okText: 'Leave',
              cancelText: 'Keep editing',
              icon: <WarningIcon />,
              okButtonProps: {
                style: {
                  backgroundColor: '#ff4d4f',
                  borderColor: '#ff4d4f',
                  color: '#fff',
                },
              },
              onOk() {
                handleClose();
                setContentState('table');
              },
              onCancel() {},
            });
          }}
        >
          Cancel
        </Button>
        <Button
          style={{
            height: '50px',
            background:
              serialNumberPassVerify?.product && customerSelect?.customerId && channelTypeSelect
                ? '#001689'
                : '',
            width: '15%',
            color:
              serialNumberPassVerify?.product && customerSelect?.customerId && channelTypeSelect
                ? 'white'
                : '',
          }}
          disabled={
            serialNumberPassVerify?.product && customerSelect?.customerId && channelTypeSelect
              ? false
              : true
          }
          onClick={() => {
            modal.confirm({
              title: <div style={{ marginLeft: '5px' }}>Create a warranty?</div>,
              content: <>Please review the details before confirming.</>,
              okText: 'Create',
              cancelText: 'Cancel',
              icon: <InfoIcon />,
              onCancel() {},
              onOk() {
                handleCreateWarranty();
              },
            });
          }}
        >
          Create
        </Button>
      </div>
    </SContainer>
  );
};
export default Create;
const SImageBox = styled.div`
  width: 100px;
  height: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-right: 20px;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }
`;

const SContainer = styled.div`
  & > div {
    display: flex;
    margin-top: 20px;
    & > div > div {
      height: 30px;
    }
    &:nth-child(1) {
      color: #737373;
      align-items: center;
      & > div > svg > g > path {
        fill: #737373;
      }
    }
    &:nth-child(2) {
      font-size: 20px;
      font-weight: bolder;
      align-items: center;

      & > div > svg {
        width: 25px;
        height: 25px;
        & > path {
          fill: black;
        }
      }
    }
    &:nth-child(3) {
      font-weight: bolder;
    }
    &:nth-child(8) {
      & > div {
        width: 95%;
      }
    }
    &:nth-child(9) {
      flex-direction: column;
      & > div {
        width: 95%;
      }
    }
    &:nth-child(10) {
      justify-content: flex-end;
      & > div {
        text-align: center;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        margin-top: 10px;
        padding: 10px 0px;
        width: 15%;
        cursor: pointer;
        margin-left: 10px;
        &:nth-child(2) {
          background-color: #001689;
          color: white;
        }
      }
    }
    &:nth-child(n + 4):nth-child(-n + 5) {
      & > div {
        width: 95%;
      }
    }
    &:nth-child(n + 6):nth-child(-n + 7) {
      & > div {
        width: 45%;
        &:nth-child(2) {
          margin-left: 5%;
        }
      }
    }
  }
`;

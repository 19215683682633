import styled from 'styled-components';
import Layout from './Layout';
import logo from '../../../assets/logo.png';
import { userAuthenticationStore } from '../../../contexts/UserAuthenticationStore';
const Default = () => {
  const user = userAuthenticationStore((state) => state);

  return (
    <Layout>
      <SContainer>
        <div style={{ fontSize: '30px', fontWeight: '500' }}>Welcome, {user.fullName}</div>
        <div
          style={{
            color: '#737373',
            fontSize: '14px',
            fontWeight: '400',
            marginTop: '20px',
          }}
        >
          An admin portal is a web interface for system administrators to manage systems, users, and
          content.
        </div>
        <div>
          <img src={logo} alt="logo" style={{ width: '500px' }} />
        </div>
      </SContainer>
    </Layout>
  );
};
export default Default;
const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

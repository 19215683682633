import { Dropdown, Image, Input, Modal, Tooltip } from 'antd';
import styled from 'styled-components';
import apiChat from '../../../../apis/chat';
import { useEffect, useMemo, useRef, useState } from 'react';
import { userAuthenticationStore } from '../../../../contexts/UserAuthenticationStore';
import chatStore from '../../../../contexts/ChatStore';
import useSocketStore from '../../../../contexts/SocketStore';
import adminImage from '../../../../assets/admin.png';
import botImage from '../../../../assets/main-logo.png';
import { ReactComponent as Arrow } from '../../../../assets/arrow.svg';
import { useNavigate } from 'react-router-dom';
import defaultAvatar from '../../../../assets/default-avatar.jpg';
import { ChatMessageType, IContentMessage } from '../../../../interfaces/chat.interface';
import { dateFormatter, formatTime } from '../../../../utils/format';
import { ReactComponent as InformationIcon } from '../../../../assets/information.svg';
import { ReactComponent as CorrectCircleIcon } from '../../../../assets/correctCircle.svg';
import { ReactComponent as QuickReplyIcon } from '../../../../assets/quick-reply.svg';
import { ReactComponent as ThreedotVertical } from '../../../../assets/threedotVertical.svg';
import { ReactComponent as ReassignIcon } from '../../../../assets/reassign.svg';
import ChatInformation from './ChatInformation';
import { ReactComponent as X } from '../../../../assets/x.svg';
import { SearchOutlined } from '@ant-design/icons';
import ReAssignPopup from './ReAssignPopup';
import apiAgent from '../../../../apis/agent';
interface IChatArea {
  selectedSessionId: string;
  status: string;
  selectedTab: string;
  setSelectedSessionId: React.Dispatch<
    React.SetStateAction<
      | {
          status: string;
          session: string;
        }
      | null
      | undefined
    >
  >;
}
const ChatArea = ({ selectedSessionId, status, selectedTab, setSelectedSessionId }: IChatArea) => {
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const tags = userAuthenticationStore((state) => state.tags);
  const adminId = userAuthenticationStore((state) => state.id);
  const [isTop, setIsTop] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [typingMessage, setTypingMessage] = useState('');
  const pageApi = useRef(1);
  const [itIsYourChat, setItIsYourChat] = useState(false);
  const selectedSessionIdRef = useRef('');
  const [canGoToBottom, setCanGoToBottom] = useState(false);
  const [isOpenInformation, setIsOpenInformation] = useState(false);
  const isBottom = useRef(true);
  const [notificationNewText, setNotificationNewText] = useState('');
  const [openQuickReply, setOpenQuickReply] = useState(false);
  const {
    chatSession,
    resetChatSession,
    setReadChat,
    apiChatSessionPageSize,
    customerDetail,
    chatSessionTotalPage,
    quickReplyList,
  } = chatStore();
  const loadingChat = useMemo(() => {
    if (chatSession.length) {
      return true;
    } else {
      return false;
    }
  }, [chatSession.length]);

  const { sendMessage, readMessage, getMessage, closeSession } = useSocketStore();
  useEffect(() => {
    if (!itIsYourChat || !selectedSessionId) return;

    readMessage({ sessionId: selectedSessionId, adminId: adminId });
    setReadChat(selectedSessionId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itIsYourChat, selectedSessionId, chatSession.length]);
  useEffect(() => {
    if (selectedSessionIdRef.current !== selectedSessionId) {
      pageApi.current = 1;
      resetChatSession();
    }
    selectedSessionIdRef.current = selectedSessionId;
    setTypingMessage('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSessionId]);
  useEffect(() => {
    if (isTop) {
      if (
        chatSession.length &&
        chatSessionTotalPage?.totalPages &&
        chatSessionTotalPage?.totalPages > pageApi.current
      ) {
        getMessage({
          sessionId: selectedSessionIdRef.current,
          page: `${pageApi.current + 1}`,
          pageSize: `${apiChatSessionPageSize}`,
        });
        pageApi.current = pageApi.current + 1;
        if (scrollRef.current) {
          scrollRef.current.scrollTop = 100;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTop]);
  useEffect(() => {
    if (selectedSessionId) {
      getMessage({
        sessionId: selectedSessionIdRef.current,
        page: `${pageApi.current}`,
        pageSize: `${apiChatSessionPageSize}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSessionId]);

  useEffect(() => {
    if (!customerDetail) return;
    if (customerDetail.status !== 'in-progress' && selectedTab !== 'history') {
      setSelectedSessionId(undefined);
      navigate('/home/chat_room');
      return;
    }
    if (customerDetail?.assignAgentId === adminId) {
      setItIsYourChat(true);
    } else {
      setItIsYourChat(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetail]);
  const checkScrollTop = () => {
    if (!scrollRef.current) {
      return;
    }
    const atTop = scrollRef.current.scrollTop === 0;
    const scrollY = scrollRef.current.scrollTop;
    const clientHeight = scrollRef.current.clientHeight;
    const scrollHeight = scrollRef.current.scrollHeight;

    if (scrollY + clientHeight >= scrollHeight - 100) {
      isBottom.current = true;
    } else {
      isBottom.current = false;
    }
    const canClickBottom = scrollRef.current.scrollHeight - scrollRef.current.scrollTop > 1000;
    setCanGoToBottom(canClickBottom);
    setIsTop(atTop);
  };
  useEffect(() => {
    const element = scrollRef.current;
    if (element) {
      element.addEventListener('scroll', checkScrollTop);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', checkScrollTop);
      }
    };
  }, []);
  useEffect(() => {
    if (!chatSession) return;
    if (isBottom.current && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    } else {
      if (chatSession?.[chatSession.length - 1]?.senderFrom === 'CUSTOMER') {
        setNotificationNewText(chatSession[chatSession.length - 1].content);
        setInterval(() => {
          setNotificationNewText('');
        }, 5000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSession.length]);
  useEffect(() => {
    if (loadingChat) {
      if (scrollRef.current) {
        if (isBottom.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      }
    }
  }, [loadingChat]);
  const handleSend = async (content: string) => {
    if (!chatSession || !customerDetail) return;
    const message = {
      content: content,
      senderFrom: 'AGENT',
      sessionId: selectedSessionId,
      customerSocialId: `${customerDetail.customer_social_channel.id}`,
      senderId: adminId,
      createdAt: new Date().toISOString(),
      roomTags: tags,
    };
    await sendMessage(message);
    setTypingMessage('');
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };
  const handleAssignAdmin = async () => {
    const result = await apiChat.adminAssign({
      sessionId: selectedSessionId,
      AdminId: adminId,
    });
    if (result) {
      setItIsYourChat(true);
    } else {
      setItIsYourChat(false);
    }
  };

  const messageFormat = (message: IContentMessage) => {
    switch (message.messageType) {
      case ChatMessageType.IMAGE:
        return <Image width={150} src={message.content} />;

      case ChatMessageType.TEXT:
        return message.content;

      default:
        return message.content;
    }
  };
  const [openPopupReAssign, setOpenPopupReAssign] = useState<boolean>(false);
  const handleGetQuickReply = async (q: string = '') => {
    await apiAgent.getQuickReply({ q });
  };
  useEffect(() => {
    handleGetQuickReply();
  }, []);
  return (
    <SContainer>
      {openPopupReAssign && (
        <ReAssignPopup
          setOpenPopupReAssign={setOpenPopupReAssign}
          selectedSessionId={selectedSessionId}
        />
      )}
      {contextHolder}
      <STop>
        <SHeader>
          <div>
            <div className="display-image">
              <img
                src={customerDetail?.customer_social_channel?.imageUrl || defaultAvatar}
                alt="display"
              />
            </div>
            <div>
              <div style={{ color: '#262626', fontSize: '14px' }}>
                {customerDetail?.customer_social_channel?.displayName}
              </div>
            </div>
            {customerDetail?.tags?.length ? (
              customerDetail?.tags.map((e, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      borderRadius: '100px',
                      border: '1px solid #06C755',
                      background: '#DCFAE6',
                      padding: ' 0px 7px',
                      fontSize: '12px',
                      marginLeft: '10px',
                    }}
                  >
                    {e}
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  borderRadius: '100px',
                  border: '1px solid #06C755',
                  background: '#DCFAE6',
                  padding: ' 0px 7px',
                  fontSize: '12px',
                  marginLeft: '10px',
                }}
              >
                {customerDetail?.customer_social_channel?.subChannel}
              </div>
            )}
            {customerDetail?.agent?.fullName && (
              <div
                style={{
                  borderRadius: '100px',
                  border: '1px solid #0620c7',
                  background: '#dce2fa',
                  padding: ' 0px 7px',
                  fontSize: '12px',
                  marginLeft: '10px',
                }}
              >
                {customerDetail?.agent?.fullName}
              </div>
            )}
            {customerDetail?.customer_social_channel?.customer_user?.tags?.length && (
              <Tooltip
                title={customerDetail?.customer_social_channel?.customer_user?.tags.join(', ')}
              >
                <div
                  style={{
                    borderRadius: '100px',
                    border: '1px solid #069dc7',
                    background: '#dcf7fa',
                    padding: ' 0px 7px',
                    fontSize: '12px',
                    marginLeft: '10px',
                  }}
                >{`+${customerDetail?.customer_social_channel?.customer_user?.tags.length}`}</div>
              </Tooltip>
            )}
          </div>
          <div>
            <div
              onClick={() => {
                setIsOpenInformation(true);
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid 1px #D9D9D9',
                padding: '1px 10px',
                borderRadius: '50px',
                cursor: 'pointer',
                marginRight: '5px',
              }}
            >
              <InformationIcon height={'14px'} width={'14px'} />
              <div style={{ marginLeft: '5px' }}>View information</div>
            </div>
            {itIsYourChat && selectedTab !== 'history' && (
              <div
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 'solid 1px #001689',
                  padding: '1px 10px',
                  borderRadius: '50px',
                  cursor: 'pointer',
                  color: '#001689',
                }}
                onClick={async () => {
                  modal.confirm({
                    title: `Close this session?`,
                    content: <>Please make sure you need to end this customer session.</>,
                    cancelText: 'Cancel',
                    onOk() {
                      closeSession({
                        sessionId: selectedSessionId,
                      });
                      setSelectedSessionId(undefined);
                      navigate('/home/chat_room');
                    },
                    okText: 'Confirm',
                  });
                }}
              >
                <CorrectCircleIcon height={'14px'} width={'14px'} />
                <div style={{ marginLeft: '5px' }}>Complete</div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                margin: '0px 30px 0px 10px',
                cursor: 'pointer',
                width: '25px',
                justifyContent: 'center',
              }}
            >
              <Dropdown
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <div style={{ display: 'flex' }}>
                          <div>
                            <ReassignIcon />
                          </div>
                          <div
                            style={{ marginLeft: '5px' }}
                            onClick={() => {
                              setOpenPopupReAssign(true);
                            }}
                          >
                            Reassign
                          </div>
                        </div>
                      ),
                    },
                  ],
                }}
                placement="bottomLeft"
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ThreedotVertical />
                </div>
              </Dropdown>
            </div>
          </div>
        </SHeader>
      </STop>

      <SChatArea className={itIsYourChat && status === 'in-progress' ? 'match' : ''}>
        <div className="history">
          <div
            className="chat-history"
            ref={scrollRef}
            style={{ width: isOpenInformation ? '60%' : '100%', borderBottom: '1px solid #bababa' }}
          >
            {chatSession &&
              chatSession.map((message, i) => {
                return (
                  <div key={i}>
                    {!chatSession?.[i - 1] ? (
                      <SDateLine>{dateFormatter(message?.createdAt || '')}</SDateLine>
                    ) : dateFormatter(message?.createdAt || '') !==
                      dateFormatter(chatSession[i - 1].createdAt || '') ? (
                      <SDateLine>{dateFormatter(message?.createdAt || '')}</SDateLine>
                    ) : (
                      ''
                    )}
                    <SMessage className={message.senderFrom} key={i}>
                      {message.senderFrom === 'CUSTOMER' ? (
                        <div className="display customer">
                          <img
                            alt="display"
                            src={customerDetail?.customer_social_channel?.imageUrl || defaultAvatar}
                          />
                        </div>
                      ) : (
                        <STimeLine className={message.senderFrom}>
                          {formatTime(message?.createdAt || '')}
                        </STimeLine>
                      )}
                      <div className="message">{messageFormat(message)}</div>
                      {message.senderFrom !== 'CUSTOMER' ? (
                        <div className="display">
                          <img
                            alt="display"
                            src={message.senderFrom === 'BOT' ? botImage : adminImage}
                          />
                        </div>
                      ) : (
                        <STimeLine className={message.senderFrom}>
                          {formatTime(message?.createdAt || '')}
                        </STimeLine>
                      )}
                    </SMessage>
                  </div>
                );
              })}
          </div>
          {isOpenInformation && (
            <SInformation className="customer-information">
              <ChatInformation setIsOpenInformation={setIsOpenInformation} />
            </SInformation>
          )}
        </div>

        {itIsYourChat && status !== 'closed' && (
          <div className="input-message">
            {notificationNewText && (
              <div
                className="notification"
                onClick={() => {
                  if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                    setNotificationNewText('');
                  }
                }}
              >
                {notificationNewText}
              </div>
            )}
            {openQuickReply && (
              <div className="quick-reply">
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>Quick replies</div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenQuickReply(false);
                    }}
                  >
                    <X />
                  </div>
                </div>
                <div style={{ lineHeight: '50px' }}>
                  <Input
                    addonBefore={<SearchOutlined />}
                    placeholder="Search"
                    width={'100%'}
                    onChange={(e) => {
                      handleGetQuickReply(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{ overflowY: 'scroll', overflowX: 'hidden', height: 'calc(100% - 100px)' }}
                >
                  {quickReplyList.length ? (
                    quickReplyList.map((e) => {
                      return (
                        <SQuickReplyOption
                          onClick={() => {
                            handleSend(e.fullMsg);
                            setOpenQuickReply(false);
                          }}
                        >{`(${e.abbrMsg}) ${e.fullMsg}`}</SQuickReplyOption>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpenQuickReply(true);
              }}
            >
              <QuickReplyIcon />
            </div>
            <Input
              onChange={(e) => {
                setTypingMessage(e.target.value);
              }}
              onPressEnter={() => typingMessage && handleSend(typingMessage)}
              value={typingMessage}
              placeholder="Write a message..."
              style={{ border: 'none', boxShadow: 'none' }}
            />
            <div className="send-button" onClick={() => typingMessage && handleSend(typingMessage)}>
              Send
            </div>
          </div>
        )}

        {canGoToBottom && (
          <div
            className="toBottom"
            onClick={() => {
              if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
              }
            }}
          >
            <div>
              <Arrow />
            </div>
          </div>
        )}
      </SChatArea>

      {!itIsYourChat && !customerDetail?.assignAgentId && selectedTab !== 'history' && (
        <SStartChat
          onClick={() => {
            handleAssignAdmin();
          }}
        >
          Start Conversation
        </SStartChat>
      )}
    </SContainer>
  );
};
export default ChatArea;
const SQuickReplyOption = styled.div`
  width: 100%;
  white-space: nowrap;
  line-height: 50px;
  border-bottom: solid 1px #f0f0f0;
  cursor: pointer;
  overflow-x: scroll;
  &:hover {
    background-color: #0591ff1a;
  }
`;
const SInformation = styled.div`
  width: 40%;
  border-left: 1px solid #f0f0f0;
  background-color: white;
`;
const SDateLine = styled.div`
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: #979797;
  padding: 5px 0px;
`;
const STimeLine = styled.div`
  font-size: 10px;
  color: #979797;
  margin-right: 5px;
  &.CUSTOMER {
    margin-right: 0px;
    margin-left: 5px;
  }
`;
const SMessage = styled.div`
  display: flex;
  align-items: center;
  & > div {
    &.message {
      border-radius: 16px;
      background: #f2f4f7;
      width: fit-content;
      max-width: 60%;
      padding: 6px 20px;
    }
    &.display {
      border-radius: 100px;
      width: 30px;
      height: 30px;
      overflow: hidden;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 7px 0px;
      &.customer {
        margin-right: 10px;
      }

      & > img {
        width: 100%;
      }
    }
  }
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  &.AGENT {
    justify-content: flex-end;
    & > div {
      &.message {
        background-color: #001689;
        color: white;
      }
    }
  }
  &.BOT {
    justify-content: flex-end;
    & > div {
      &.message {
        background-color: #459afd;
        color: white;
      }
    }
  }
`;
const SChatArea = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  background-color: #f1f1f1;
  &.match {
    background-color: white;
  }
  & > div {
    &.toBottom {
      position: absolute;
      bottom: 100px;
      right: 0;
      cursor: pointer;
      background-color: rgba(16, 24, 40, 0.65);
      border-radius: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(0.75turn);
        & > svg > g > path {
          fill: white;
        }
      }
    }
    &.history {
      height: 90%;
      display: flex;
      & > div {
        &.chat-history {
          padding: 0px 10px;
          overflow: scroll;
          height: 100%;
        }
      }
    }
    &.input-message {
      background-color: #fff;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
      height: 10%;
      & > div {
        &.quick-reply {
          position: absolute;
          top: -320px;
          left: 0;
          height: 300px;
          width: 100%;
          box-shadow: 0px -2px 10px 0px #9aaacf1a;
          background: #fcfcfd;
          border-radius: 30px 30px 0px 0px;
          overflow: hidden;
          padding: 10px;
          & > div {
            width: calc(100% - 20px);
          }
        }
        &.notification {
          position: absolute;
          background-color: rgba(99, 99, 99, 0.8);
          color: white;
          padding: 5px 10px;
          top: -30px;
          cursor: pointer;
          width: 100%;
          left: 0;
        }
        &.send-button {
          border-radius: 8px;
          background: #001689;
          color: #fff;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }
`;
const SContainer = styled.div`
  width: 75%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;
const STop = styled.div`
  height: 60px;
  background-color: #fcfcfd;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
`;
const SHeader = styled.div`
  display: flex;
  height: 100%;
  margin-left: 20px;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.menu {
      cursor: pointer;
    }
    & > div {
      &.display-image {
        width: 42.5px;
        height: 42.5px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        background-color: grey;
        margin-right: 10px;
        & > img {
          width: 100%;
        }
      }
    }
  }
`;
const SStartChat = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100px;
  border: 1px solid #001689;
  background: #001689;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  padding: 6.4px 15px;
  color: #fff;
  cursor: pointer;
`;

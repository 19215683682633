import { create } from 'zustand';
import {
  IChatList,
  IChatListSession,
  IContentMessage,
  ICustomerDetail,
  IPagination,
} from '../interfaces/chat.interface';
import { userAuthenticationStore } from './UserAuthenticationStore';
import { IQuickReply } from '../interfaces/agent.interface';
interface IChatStoreState {
  chatListLoading: boolean;
  turnOnLoading: () => void;
  turnOffLoading: () => void;
  pageApi: number;
  resetPageApi: () => void;
  allChatList: IChatListSession[];
  chatListTotalPage: IPagination | undefined;
  resetChatListTotalPage: () => void;
  resetChatSessionTotalPage: () => void;
  resetCustomerDetail: () => void;
  addChatList: (data: IChatList) => void;
  addNewChatList: (data: IChatListSession) => void;
  setAllChatListTotalPage: (page: IPagination) => void;
  setAllChatList: (data: IChatList) => void;
  setChatSession: (message: IContentMessage[]) => void;
  setReadChat: (sessionId: string) => void;
  updateLastMessage: (data: any) => void;
  chatSession: IContentMessage[];
  createChatSession: (selectedSessionId: string, data: IContentMessage[]) => void;
  resetChatSession: () => void;
  getHistoryChatSession: (data: any) => void;
  getNewChatSession: (data: any) => void;
  addSendChatSession: (data: any) => void;
  chatSessionTotalPage: IPagination | undefined;
  setChatSessionTotalPage: (data: IPagination) => void;
  apiChatSessionPageSize: number;
  customerDetail: ICustomerDetail | undefined;
  setCustomerDetails: (data: ICustomerDetail) => void;
  resetAllChatList: () => void;
  removeChatListBySessionId: (sessionId: string) => void;
  takeSession: ({ assignAgentId, sessionId }: { assignAgentId: string; sessionId: string }) => void;
  quickReplyList: IQuickReply[] | [];
  setQuickReplyList: (value: IQuickReply[] | []) => void;
  totalQuickReply: number;
  setTotalQuickReply: (value: number) => void;
}
const chatStore = create<IChatStoreState>((set) => ({
  totalQuickReply: 0,
  setTotalQuickReply: (value) => {
    set(() => {
      return { totalQuickReply: value };
    });
  },
  quickReplyList: [],
  setQuickReplyList: (value) => {
    set(() => {
      return { quickReplyList: value };
    });
  },
  chatListLoading: false,
  turnOnLoading: () => {
    set(() => {
      return { chatListLoading: true };
    });
  },
  turnOffLoading: () => {
    set(() => {
      return { chatListLoading: false };
    });
  },
  pageApi: 1,
  resetPageApi: () => {
    set(() => {
      return { pageApi: 1 };
    });
  },
  chatSessionTotalPage: {
    totalRecords: 0,
    totalPages: 0,
    currentPage: 1,
    currentPageSize: '',
  },
  resetChatSessionTotalPage: () => {
    set((state) => {
      return {
        chatSessionTotalPage: {
          totalRecords: 0,
          totalPages: 0,
          currentPage: 1,
          currentPageSize: '',
        },
      };
    });
  },
  setChatSessionTotalPage: (data) =>
    set((state) => {
      return { chatSessionTotalPage: data };
    }),
  allChatList: [],
  customerDetail: undefined,
  takeSession: ({ assignAgentId, sessionId }) => {
    set((state) => {
      const result = [...state.allChatList];
      const index = result.findIndex((e) => e.session.id === sessionId);
      if (index > -1) {
        result[index].session.assignAgentId = assignAgentId;
        return { allChatList: result };
      } else {
        return {};
      }
    });
  },
  resetCustomerDetail: () => {
    set((state) => {
      return { customerDetail: undefined };
    });
  },
  removeChatListBySessionId: (sessionId: string) =>
    set((state) => {
      const allChat = [...state.allChatList];
      const result = allChat.filter((e) => e.session.id !== sessionId);
      return { allChatList: result };
    }),
  setCustomerDetails: (data) =>
    set((state) => {
      return { customerDetail: data };
    }),
  chatListTotalPage: undefined,
  resetChatListTotalPage: () =>
    set((state) => {
      return { chatListTotalPage: undefined };
    }),
  addChatList: (data) =>
    set((state) => {
      const currentPageApi =
        data.pagination.currentPage >= data.pagination.totalPages
          ? data.pagination.currentPage
          : data.pagination.currentPage + 1;
      const result = [...state.allChatList];
      result.push(...data.chatSessions);
      // return { allChatList: result, pageApi: currentPageApi };
      return { allChatList: result, pageApi: 1 };
    }),
  addNewChatList: (data) =>
    set((state) => {
      if (!data) return { allChatList: state.allChatList };
      const result = [...state.allChatList];
      result.unshift(data);
      return { allChatList: result };
    }),
  setAllChatListTotalPage: (page) =>
    set(() => {
      return { chatListTotalPage: page };
    }),
  setAllChatList: (data) =>
    set((state) => {
      const currentPageApi =
        data.pagination.currentPage >= data.pagination.totalPages
          ? data.pagination.currentPage
          : data.pagination.currentPage + 1;
      // return { allChatList: data.chatSessions, pageApi: currentPageApi };
      return { allChatList: data.chatSessions, pageApi: 1 };
    }),
  resetAllChatList: () =>
    set(() => {
      return { allChatList: [] };
    }),
  setReadChat: (sessionId) =>
    set((state) => {
      if (!state.allChatList.length) return { allChatList: state.allChatList };
      const indexObject = state.allChatList.findIndex((obj) => obj.session.id === sessionId);

      const result = [...state.allChatList];
      result[indexObject].newMessageCount = 0;
      return { allChatList: result };
    }),
  updateLastMessage: (data) =>
    set((state) => {
      const indexObject = state.allChatList.findIndex((obj) => obj.session.id === data.sessionId);
      const result = [...state.allChatList];

      if (!result[indexObject]?.latestMessage?.content) {
        result[indexObject].latestMessage = {
          id: result[indexObject]?.latestMessage?.id + 1 || 0,
          sessionId: data.sessionId,
          senderFrom: data.senderFrom,
          content: '',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
      }
      result[indexObject].latestMessage.content = data.content;
      const queryParams = new URLSearchParams(window.location.search);
      const param = queryParams.get('session');

      if (
        userAuthenticationStore.getState().id === result[indexObject].session.assignAgentId &&
        data.sessionId === param
      ) {
        result[indexObject].newMessageCount = 0;
      } else {
        result[indexObject].newMessageCount = data.newMessageCount;
      }

      result[indexObject].latestMessage.updatedAt = new Date().toISOString();

      return { allChatList: result };
    }),
  chatSession: [],
  getHistoryChatSession: (data) =>
    set((state) => {
      if (!data[0]) return {};
      if (state.chatSession[0].sessionId === data[0].sessionId) {
        const result = [...state.chatSession];
        result.unshift(...data);

        return { chatSession: result };
      } else {
        return {};
      }
    }),
  getNewChatSession: (data) =>
    set((state) => {
      if (!state.chatSession[0]) return {};
      if (state.chatSession[0].sessionId === data[0].sessionId) {
        const result = [...state.chatSession];
        result.push(...data);

        return { chatSession: result };
      } else {
        return {};
      }
    }),
  addSendChatSession: (data) =>
    set((state) => {
      const result = [...state.chatSession];
      result.push(...data);
      return { chatSession: result };
    }),
  setChatSession: (data) =>
    set(() => {
      return { chatSession: data };
    }),
  createChatSession: (selectedSessionId, data) =>
    set(() => {
      return { chatSession: data };
    }),
  resetChatSession: () =>
    set(() => {
      return { chatSession: [] };
    }),

  apiChatSessionPageSize: 40,
}));
export default chatStore;

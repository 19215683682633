import styled from 'styled-components';
import Layout from './Layout';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Modal, Table, TableColumnsType } from 'antd';
import apiPermission from '../../../apis/permission';
import { IDataSourceType, IStaffMenu } from '../../../interfaces/permission.interface';

const PermissionMatrix = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [staffMenu, setStaffMenu] = useState<IStaffMenu>({ roleData: [], permissionData: [] });
  const handlePermissionData = async () => {
    const permissionList: IStaffMenu = await apiPermission.getPermissionList();
    setStaffMenu(permissionList);
  };
  useEffect(() => {
    handlePermissionData();
  }, []);
  const handlePermissionChange = (roleId: string, permissionId: string, checked: boolean) => {
    setStaffMenu((prevMenu) => ({
      ...prevMenu,
      permissionData: prevMenu.permissionData.map((permission) =>
        permission.id === permissionId
          ? {
              ...permission,
              permissions: {
                ...permission.permissions,
                [roleId]: checked,
              },
            }
          : permission,
      ),
    }));
  };
  const columns: TableColumnsType<IDataSourceType> = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    ...staffMenu.permissionData.map((permission) => ({
      title: permission.name,
      dataIndex: permission.id,
      key: permission.id,
      render: (value: boolean, record: IDataSourceType) => (
        <Checkbox
          checked={value}
          onChange={(e) =>
            handlePermissionChange(record.key as string, permission.id, e.target.checked)
          }
        />
      ),
    })),
  ];
  const dataSource: IDataSourceType[] = staffMenu.roleData.map((role) => {
    const row: IDataSourceType = { key: role.id, role: role.title };
    staffMenu.permissionData.forEach((permission) => {
      row[permission.id] = permission.permissions[role.id];
    });
    return row;
  });
  const handleSave = async () => {
    await modal.confirm({
      title: 'Do you want to save permission?',
      content: <>The role permission will change.</>,
      onOk() {
        apiPermission.updatePermissionList(staffMenu.permissionData);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  return (
    <Layout>
      <SContainer>
        {contextHolder}
        <SHeader>
          <div>
            <div style={{ fontSize: '20px', fontWeight: 700 }}>Permission Matrix</div>
          </div>
        </SHeader>
        <Table
          className="custom-table"
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 1500 }}
          pagination={false}
          rowKey={'roleId'}
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        />
        <div className="btn">
          <Button
            style={{ width: '15%', height: '50px', background: '#001689', color: 'white' }}
            onClick={async () => {
              handleSave();
            }}
          >
            Save changes
          </Button>
        </div>
      </SContainer>
    </Layout>
  );
};

export default PermissionMatrix;
const SHeader = styled.div`
  display: grid;
  grid-template: 1fr/2fr 1fr;
`;
const SContainer = styled.div`
  .custom-table .ant-table-thead > tr > th {
    font-weight: bold;
    text-align: center;
    background-color: #fcfcfd;
  }

  .custom-table .ant-table-tbody > tr > td {
    text-align: center;
    background-color: #fcfcfd;
  }

  .custom-table .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1a73e8;
    border-color: #1a73e8;
  }

  .custom-table .ant-checkbox-inner {
    width: 16px;
    height: 16px;
  }
  height: 95% !important;
  .btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .custom-table .ant-table-cell {
    font-size: 14px;
  }
  .no {
    color: #1890ff;
  }
  .customer {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      width: 30px;
      height: 30px;
      overflow: hidden;
      background: grey;
      border-radius: 50px;
      margin-right: 5px;
      & > img {
        height: 100%;
      }
    }
  }
  .slip {
    cursor: pointer;
  }
  .channel {
    border: 1px solid;
    border-radius: 100px;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    & > div:nth-child(1) {
      margin-right: 2px;
    }
    &.line {
      border-color: #06c755;
      background-color: #dcfae6;
    }
    &.facebook_messenger {
      border-color: #0866ff;
      background-color: #e6f0ff;
    }
    &.widget {
      border-color: #f79009;
      background-color: #fffaeb;
    }
  }
  .hashtag {
    width: fit-content;
    border-radius: 100px;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdeaf2;
    color: #ed2e7b;
  }
  .status {
    border-radius: 100px;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    &.approved {
      background-color: #e2ffe9;
      color: #17b26a;
    }
    &.rejected {
      background-color: #fef3f2;
      color: #f04438;
    }
    &.pending {
      background-color: #fffaeb;
      color: #f79009;
    }
    &.inCompleted {
      background-color: #eaecf0;
      color: #737373;
    }
  }
  .option {
    cursor: pointer;
    position: relative;
  }
`;

import { instance } from './instance';

interface IGetCustomerList {
  q: string;
  page?: string;
  pageSize?: string;
}
const getCustomerList = async ({ q = '', page = '1', pageSize = '10' }: IGetCustomerList) => {
  try {
    const result = await instance.get(`/customer/list`, {
      params: { q: q, page: page, pageSize: pageSize, hideWebWidget: true },
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface ISaveCustomer {
  userId: string;
  subDistrictID: number;
  districtID: number;
  provinceID: number;
  regionID: number;
  postCode: string;
  tags: {
    aux: string[];
    std: number[];
  };
  acceptConsent: boolean;
}
const saveCustomer = async ({
  userId,
  subDistrictID,
  districtID,
  provinceID,
  regionID,
  postCode,
  tags,
  acceptConsent,
}: ISaveCustomer) => {
  try {
    const result = await instance.patch(`/customer/data/${userId}`, {
      subDistrictID,
      districtID,
      provinceID,
      regionID,
      postCode,
      tags,
      acceptConsent,
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
const getStandardTags = async () => {
  try {
    const result = await instance.get(`/customer/standardTags`);
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
const apiCustomer = {
  getCustomerList,
  saveCustomer,
  getStandardTags,
};
export default apiCustomer;

import { useEffect, useState } from 'react';
import { Button, Input, Select, Upload, message } from 'antd';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import { HookAPI } from 'antd/es/modal/useModal';
import { ReactComponent as WarningIcon } from '../../../../assets/warningIcon.svg';
import { ReactComponent as Arrow } from '../../../../assets/arrow.svg';
import defaultAvatar from '../../../../assets/default-avatar.jpg';
import apiAgent from '../../../../apis/agent';
import manageAgentStore from '../../../../contexts/ManageAgentStore';
import {
  IAgent,
  CreateAgentParameters,
  UpdateAgentParameters,
} from '../../../../interfaces/agent.interface';

interface ICreate {
  setContentState: React.Dispatch<React.SetStateAction<'table' | 'create' | 'update'>>;
  selectedRecord: IAgent | undefined;
  modal: HookAPI;
}

interface AgentRole {
  id: string;
  title: string;
}

interface ChannelRecord {
  id: string;
  name: string;
  tagName: string;
}

interface DepartmentRecord {
  id: number;
  agentChannelID: string;
  name: string;
}

interface LanguageRecord {
  id: string;
  name: string;
}

interface GetConstantsData {
  roles: AgentRole[];
  channels: ChannelRecord[];
  departments: DepartmentRecord[];
  languages: LanguageRecord[];
}

interface DropdownMenu {
  roles: { value: string; label: string }[];
  channels: { value: string; label: string }[];
  departments: { value: number; label: string }[];
  languages: { value: string; label: string }[];
}

const CreateUpdate = ({ setContentState, selectedRecord, modal }: ICreate) => {
  const [firstName, setFirstName] = useState<string | undefined>(
    selectedRecord?.firstName ? selectedRecord.firstName : undefined,
  );
  const [lastName, setLastName] = useState<string | undefined>(
    selectedRecord?.lastName ? selectedRecord.lastName : undefined,
  );
  const [email, setEmail] = useState<string | undefined>(
    selectedRecord?.email ? selectedRecord.email : undefined,
  );
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    selectedRecord?.phoneNumber ? selectedRecord.phoneNumber : undefined,
  );
  const [roleID, setRoleID] = useState<string | undefined>(
    selectedRecord?.roleID ? selectedRecord.roleID : undefined,
  );
  const [channelID, setChannelID] = useState<string | undefined>(undefined);
  const [departmentID, setDepartmentID] = useState<number | undefined>(undefined);
  const [languageID, setLanguageID] = useState<string | undefined>(undefined);

  const [allowSubmit, setAllowSubmit] = useState(false);

  const [roleDropdown, setRoleDropdown] = useState<{ value: string; label: string }[]>([]);
  const [channelDropdown, setChannelDropdown] = useState<{ value: string; label: string }[]>([]);
  const [departmentDropdown, setDepartmentDropdown] = useState<{ value: number; label: string }[]>(
    [],
  );
  const [languageDropdown, setLanguageDropdown] = useState<{ value: string; label: string }[]>([]);

  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState<string | undefined>(undefined);
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState<string | undefined>(undefined);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string | undefined>(undefined);
  const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] = useState<string | undefined>(undefined);
  const [roleIDErrorMsg, setRoleIDErrorMsg] = useState<string | undefined>(undefined);
  const [channelIDErrorMsg, setChannelIDErrorMsg] = useState<string | undefined>(undefined);
  const [departmentIDErrorMsg, setDepartmentIDErrorMsg] = useState<string | undefined>(undefined);
  const [languageIDErrorMsg, setLanguageIDErrorMsg] = useState<string | undefined>(undefined);

  const { agentConstantContent } = manageAgentStore();
  const adminID = 'admin';
  const isCreate = selectedRecord === undefined;

  useEffect(() => {
    if (!agentConstantContent) {
      apiAgent.getAgentConstantContent().then((constants: GetConstantsData) => {
        generateDropdownMenus(constants);
        if (!isCreate) insertAffiliationDataForUpdateCase(constants, selectedRecord);
      });
    } else {
      generateDropdownMenus(agentConstantContent);
      if (!isCreate) insertAffiliationDataForUpdateCase(agentConstantContent, selectedRecord);
    }
  }, [agentConstantContent]);

  useEffect(() => {
    if (roleID === adminID) {
      setChannelID(undefined);
      setDepartmentID(undefined);
      setLanguageID(undefined);
    }
  }, [roleID]);

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      email &&
      phoneNumber &&
      roleID &&
      (roleID === adminID || (roleID !== adminID && channelID && departmentID && languageID))
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [firstName, lastName, email, phoneNumber, roleID, channelID, departmentID, languageID]);

  const insertAffiliationDataForUpdateCase = (
    constantData: GetConstantsData,
    agentData: IAgent,
  ) => {
    const roleIndex = constantData.roles.findIndex((value) => value.id === agentData.roleID);
    if (roleIndex !== -1) setRoleID(constantData.roles[roleIndex].id);
    if (agentData.roleID !== adminID) {
      const channelIndex = constantData.channels.findIndex(
        (value) => value.id === agentData.channelID,
      );
      const departmentIndex = constantData.departments.findIndex(
        (value) => value.id === agentData.departmentID,
      );
      const languageIndex = constantData.languages.findIndex(
        (value) => value.id === agentData.languageID,
      );

      if (channelIndex !== -1) setChannelID(constantData.channels[channelIndex].id);
      if (departmentIndex !== -1) setDepartmentID(constantData.departments[departmentIndex].id);
      if (languageIndex !== -1) setLanguageID(constantData.languages[languageIndex].id);
    }
  };

  const disableAgentAffiliation = () => {
    return roleID === undefined || roleID === adminID;
  };

  const validateEmailFormat = (email: string) => {
    return /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email,
    );
  };

  const generateDropdownMenus = (data: GetConstantsData) => {
    const dropdowns: DropdownMenu = {
      roles: [],
      channels: [],
      departments: [],
      languages: [],
    };

    if (data.roles.length > 0) {
      data.roles.forEach((eachData) => {
        if (
          dropdowns.roles.findIndex((dropdownData) => dropdownData.value === eachData.id) === -1
        ) {
          dropdowns.roles.push({ value: eachData.id, label: eachData.title });
        }
      });

      setRoleDropdown(dropdowns.roles);
    }

    if (data.channels.length > 0) {
      data.channels.forEach((eachData) => {
        if (
          dropdowns.channels.findIndex((dropdownData) => dropdownData.value === eachData.id) === -1
        ) {
          dropdowns.channels.push({ value: eachData.id, label: eachData.name });
        }
      });

      setChannelDropdown(dropdowns.channels);
    }

    if (!isCreate && agentConstantContent && data.departments.length > 0) {
      data.departments.forEach((eachData) => {
        if (
          eachData.agentChannelID === selectedRecord.channelID &&
          dropdowns.departments.findIndex((dropdownData) => {
            return dropdownData.value === eachData.id;
          }) === -1
        ) {
          dropdowns.departments.push({
            value: eachData.id,
            label: `[${eachData.agentChannelID}] ${eachData.name}`,
          });
        }
      });

      setDepartmentDropdown(dropdowns.departments);
    }

    if (data.languages.length > 0) {
      data.languages.forEach((eachData) => {
        if (
          dropdowns.languages.findIndex((dropdownData) => dropdownData.value === eachData.id) === -1
        ) {
          dropdowns.languages.push({ value: eachData.id, label: eachData.name });
        }
      });

      setLanguageDropdown(dropdowns.languages);
    }
  };

  const handleFirstNameField = (firstName: string) => {
    setFirstName(firstName);
  };

  const handleLastLameField = (lastName: string) => {
    setLastName(lastName);
  };

  const handleEmailField = (email: string) => {
    setEmail(email);
  };

  const handlePhoneNumber = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
  };

  const handleRoleSelect = (selectedID: string) => {
    setRoleID(selectedID);
  };

  const handleChannelSelect = (selectedID: string) => {
    setChannelID(selectedID);
    setDepartmentID(undefined);
    if (agentConstantContent && agentConstantContent.departments.length > 0) {
      const departmentDropdown: { value: number; label: string }[] = [];
      agentConstantContent.departments.forEach((eachData) => {
        if (
          eachData.agentChannelID === selectedID &&
          departmentDropdown.findIndex((data) => data.value === eachData.id) === -1
        ) {
          departmentDropdown.push({
            value: eachData.id,
            label: `[${eachData.agentChannelID}] ${eachData.name}`,
          });
        }
      });

      setDepartmentDropdown(departmentDropdown);
    }
  };

  const handleDepartmentSelect = (selectedID: number) => {
    setDepartmentID(selectedID);
  };

  const handleLanguageSelect = (selectedID: string) => {
    setLanguageID(selectedID);
  };

  const handleClose = () => {
    setFirstName(undefined);
    setLastName(undefined);
    setEmail(undefined);
    setPhoneNumber(undefined);
    setRoleID(undefined);
    setChannelID(undefined);
    setDepartmentID(undefined);
    setLanguageID(undefined);
    setRoleDropdown([]);
    setChannelDropdown([]);
    setDepartmentDropdown([]);
    setLanguageDropdown([]);
  };

  const handleSubmit = async () => {
    setFirstNameErrorMsg(undefined);
    setLastNameErrorMsg(undefined);
    setEmailErrorMsg(undefined);
    setPhoneNumberErrorMsg(undefined);
    setRoleIDErrorMsg(undefined);
    setChannelIDErrorMsg(undefined);
    setDepartmentIDErrorMsg(undefined);
    setLanguageIDErrorMsg(undefined);

    if (!firstName || firstName.length <= 0) setFirstNameErrorMsg('First name cannot be empty');
    if (!lastName || lastName.length <= 0) setLastNameErrorMsg('Last name cannot be empty');
    if (!phoneNumber || phoneNumber.length <= 0)
      setPhoneNumberErrorMsg('Phone number cannot be empty');
    if (!roleID || roleID.length <= 0) setRoleIDErrorMsg('Role cannot be empty');

    if (roleID !== adminID) {
      if (!channelID || channelID.length <= 0) setChannelIDErrorMsg('Channel cannot be empty');
      if (!departmentID) setDepartmentIDErrorMsg('Department cannot be empty');
      if (!languageID || languageID.length <= 0) setLanguageIDErrorMsg('Language cannot be empty');
    }

    let isEmailValid = false;
    if (email && email.length > 0) {
      isEmailValid = validateEmailFormat(email);
      if (!isEmailValid) setEmailErrorMsg('Invalid email format');
    } else {
      setEmailErrorMsg('Email cannot be empty');
    }

    if (
      firstName &&
      lastName &&
      email &&
      isEmailValid &&
      phoneNumber &&
      roleID &&
      (roleID === adminID || (roleID !== adminID && channelID && departmentID && languageID))
    ) {
      if (isCreate) {
        const params: CreateAgentParameters = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          roleID: roleID,
        };

        if (roleID !== adminID) {
          params.channelID = channelID;
          params.departmentID = departmentID;
          params.languageID = languageID;
        }

        const APIResponse = await apiAgent.registerNewAgent(params);
        if (APIResponse.createdAgentData) {
          message.success('Agent successfully created!');
          handleClose();
          setContentState('table');
        } else {
          handleErrorMsg(APIResponse.response);
        }
      } else if (!isCreate && selectedRecord.id) {
        const params: UpdateAgentParameters = {};
        if (selectedRecord.firstName !== firstName) params.firstName = firstName;
        if (selectedRecord.lastName !== lastName) params.lastName = lastName;
        if (selectedRecord.email !== email) params.email = email;
        if (selectedRecord.phoneNumber !== phoneNumber) params.phoneNumber = phoneNumber;
        if (selectedRecord.roleID !== roleID) params.roleID = roleID;

        if (roleID !== adminID) {
          if (selectedRecord.channelID !== channelID) params.channelID = channelID;
          if (selectedRecord.departmentID !== departmentID) params.departmentID = departmentID;
          if (selectedRecord.languageID !== languageID) params.languageID = languageID;
        }

        if (Object.keys(params).length > 0) {
          const APIResponse = await apiAgent.updateAgentByID(params, selectedRecord.id);
          if (APIResponse.updatedAgentData) {
            message.success('Agent successfully updated!');
            handleClose();
            setContentState('table');
          } else {
            handleErrorMsg(APIResponse.response);
          }
        } else {
          message.success('Agent successfully updated!');
          handleClose();
          setContentState('table');
        }
      }
    }
  };

  const handleErrorMsg = (APIResponse: {
    status: number;
    data: {
      error?: string;
      data?: { duplicatedFieldList: { email: boolean; phoneNumber: boolean } };
    };
  }) => {
    if (APIResponse.status === 400) {
      if (APIResponse.data.error === 'This email or phone number is already used') {
        if (APIResponse?.data?.data?.duplicatedFieldList.email) {
          setEmailErrorMsg('This email is already used. Please try another.');
        }
        if (APIResponse?.data?.data?.duplicatedFieldList.phoneNumber) {
          setPhoneNumberErrorMsg('This phone number is already used. Please try another.');
        }
      }
    } else {
      console.error(
        `[${
          isCreate ? 'CreateAgent' : 'UpdateAgent'
        }]: Error occers when performing the action as follow.`,
      );
      console.error(APIResponse);
    }
  };

  return (
    <SContainer>
      <div className="breadcrumb">
        <div
          onClick={() => {
            modal.confirm({
              title: <div style={{ marginLeft: '5px' }}>Leave page?</div>,
              content: <>Any unsaved changes will be lost.</>,
              okText: 'Leave',
              cancelText: 'Keep editing',
              icon: <WarningIcon />,
              okButtonProps: {
                style: {
                  backgroundColor: '#ff4d4f',
                  borderColor: '#ff4d4f',
                  color: '#fff',
                },
              },
              onOk() {
                handleClose();
                setContentState('table');
              },
              onCancel() {},
            });
          }}
        >
          <Arrow fill="#737373" />
          Back
        </div>
      </div>
      <div className="main-topic">{isCreate ? 'Create New Agent' : 'Update Agent'}</div>
      <div className="image-panel">
        <div className="title">
          <div className="black-text">Profile</div>
          <div className="gray-text">(Optional)</div>
        </div>
        <div className="upload-block">
          <SImageBox>
            <img src={defaultAvatar} alt="default"></img>
          </SImageBox>
          <Upload showUploadList={false}>
            <Button icon={<UploadOutlined />} disabled={true}>
              Upload
            </Button>
            <div className="gray-text">Upload .png or .jpeg files up to 10MB</div>
          </Upload>
        </div>
      </div>
      <div className="topic">Agent Information</div>
      <div className="input-panel">
        <div className="input-block">
          <div>First name</div>
          <div>
            <Input
              placeholder="Enter the first name"
              value={firstName}
              onChange={(e) => {
                handleFirstNameField(e.target.value);
              }}
            />
            {firstNameErrorMsg ? <div className="error-input-msg">{firstNameErrorMsg}</div> : ''}
          </div>
        </div>
        <div className="input-block">
          <div>Last name</div>
          <div>
            <Input
              placeholder="Enter the last name"
              value={lastName}
              onChange={(e) => {
                handleLastLameField(e.target.value);
              }}
            />
            {lastNameErrorMsg ? <div className="error-input-msg">{lastNameErrorMsg}</div> : ''}
          </div>
        </div>
        <div className="input-block">
          <div>Email</div>
          <div>
            <Input
              placeholder="e.g. name@example.com"
              value={email}
              onChange={(e) => {
                handleEmailField(e.target.value);
              }}
            />
            {emailErrorMsg ? <div className="error-input-msg">{emailErrorMsg}</div> : ''}
          </div>
        </div>
      </div>
      <div className="input-panel">
        <div className="input-block">
          <div>Phone number</div>
          <div>
            <Input
              placeholder="e.g. 1234567890"
              value={phoneNumber}
              onChange={(e) => {
                handlePhoneNumber(e.target.value);
              }}
            />
            {phoneNumberErrorMsg ? (
              <div className="error-input-msg">{phoneNumberErrorMsg}</div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="input-block">
          <div>Role</div>
          <div>
            <Select
              placeholder="Select the role"
              style={{ width: '100%' }}
              value={roleID}
              onChange={handleRoleSelect}
              options={roleDropdown}
              filterOption={false}
            />
            {roleIDErrorMsg ? <div className="error-input-msg">{roleIDErrorMsg}</div> : ''}
          </div>
        </div>
        <div></div>
      </div>
      <div className="topic">Agent Affiliation</div>
      <div className="input-panel">
        <div className="input-block">
          <div>Channel</div>
          <div>
            <Select
              placeholder="Select the channel"
              style={{ width: '100%' }}
              value={channelID}
              onChange={handleChannelSelect}
              options={channelDropdown}
              disabled={disableAgentAffiliation()}
            />
            {channelIDErrorMsg ? <div className="error-input-msg">{channelIDErrorMsg}</div> : ''}
          </div>
        </div>
        <div className="input-block">
          <div>Department</div>
          <div>
            <Select
              placeholder="Select the department"
              style={{ width: '100%' }}
              value={departmentID}
              onChange={handleDepartmentSelect}
              options={departmentDropdown}
              disabled={disableAgentAffiliation() || !channelID}
            />
            {departmentIDErrorMsg ? (
              <div className="error-input-msg">{departmentIDErrorMsg}</div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="input-block">
          <div>Language</div>
          <div>
            <Select
              placeholder="Select the language"
              style={{ width: '100%' }}
              value={languageID}
              onChange={handleLanguageSelect}
              options={languageDropdown}
              disabled={disableAgentAffiliation()}
            />
            {languageIDErrorMsg ? <div className="error-input-msg">{languageIDErrorMsg}</div> : ''}
          </div>
        </div>
      </div>
      <div className="submit-btn-panel">
        <Button
          style={{ width: '15%', marginRight: '20px', height: '50px' }}
          onClick={() => {
            modal.confirm({
              title: <div style={{ marginLeft: '5px' }}>Leave page?</div>,
              content: <>Any unsaved changes will be lost.</>,
              okText: 'Leave',
              cancelText: 'Keep editing',
              icon: <WarningIcon />,
              okButtonProps: {
                style: {
                  backgroundColor: '#ff4d4f',
                  borderColor: '#ff4d4f',
                  color: '#fff',
                },
              },
              onOk() {
                handleClose();
                setContentState('table');
              },
              onCancel() {},
            });
          }}
        >
          Cancel
        </Button>
        <Button
          style={{
            background: allowSubmit ? '#001689' : '',
            width: '15%',
            color: allowSubmit ? 'white' : '',
            height: '50px',
          }}
          disabled={!allowSubmit}
          onClick={() => {
            modal.confirm({
              title: `${isCreate ? 'Create' : 'Update'} an agent?`,
              content: <>Please review the detail before confirming.</>,
              okText: isCreate ? 'Create' : 'Update',
              cancelText: 'Cancel',
              onOk() {
                handleSubmit();
              },
            });
          }}
        >
          {isCreate ? 'Create' : 'Update'}
        </Button>
      </div>
    </SContainer>
  );
};

export default CreateUpdate;

const SImageBox = styled.div`
  width: 100px;
  height: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    opacity: 50%;
  }
`;

const SContainer = styled.div`
  .breadcrumb {
    cursor: pointer;
    color: #737373;
    align-items: center;
    & > div > svg > g > path {
      fill: #737373;
    }
  }

  .main-topic {
    font-size: 20px;
    font-weight: bolder;
    align-items: center;

    & > svg {
      width: 25px;
      height: 25px;
      & > path {
        fill: black;
      }
    }
  }

  .topic {
    font-weight: bolder;
  }

  .image-panel {
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
    }
    .upload-block {
      display: flex;
      align-items: center;
    }
  }

  .black-text {
    margin-right: 5px;
  }

  .gray-text {
    color: #bfbfbf;
  }

  .input-panel {
    & > div {
      &:nth-child(n + 2) {
        margin-left: 5%;
      }
    }
  }

  .input-block {
    width: 28%;
  }

  .submit-btn-panel {
    justify-content: flex-end;
    margin-top: 80px;
  }

  .error-input-msg {
    font-size: 12px;
    color: #cc0000;
  }

  & > div {
    display: flex;
    margin-top: 20px;
    & > div > div {
      height: 30px;
    }
  }
`;

import styled from 'styled-components';
import User from './top-components/User';
import { userAuthenticationStore } from '../../contexts/UserAuthenticationStore';
import adminImage from '../../assets/admin.png';

const Top = () => {
  const user = userAuthenticationStore((state) => state);
  return (
    <SContainer>
      <User display={adminImage} name={user.fullName} role={user.role} />
    </SContainer>
  );
};
export default Top;
const SContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #fcfcfd;
  border-bottom: 1px solid #d9d9d9;
`;

import styled from 'styled-components';
import chatStore from '../../../../contexts/ChatStore';
import { useMemo } from 'react';
import { ReactComponent as X } from '../../../../assets/x.svg';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { dateFormatter } from '../../../../utils/format';
import pageStore from '../../../../contexts/PageStore';
import { useNavigate } from 'react-router-dom';

interface IChatInformation {
  setIsOpenInformation: React.Dispatch<React.SetStateAction<boolean>>;
}
const ChatInformation = ({ setIsOpenInformation }: IChatInformation) => {
  const { setContentSelected } = pageStore();
  const navigate = useNavigate();
  const { customerDetail } = chatStore();
  const customerUser = useMemo(() => {
    if (customerDetail?.customer_social_channel.customer_user) {
      return {
        customer_user: customerDetail?.customer_social_channel?.customer_user,
        customer_address_data: customerDetail?.customer_address_data,
      };
    } else {
      return undefined;
    }
  }, [customerDetail]);
  return (
    <SContainer>
      <div>
        <STopic>
          <div>Customer Information</div>
          <div
            onClick={() => {
              setIsOpenInformation(false);
            }}
          >
            <X />
          </div>
        </STopic>
        <SBullet>
          <div>Customer name</div>
          <div>{`${customerUser?.customer_user?.firstName || ''} ${
            customerUser?.customer_user?.lastName || ''
          }`}</div>
        </SBullet>
        <SBullet>
          <div>Phone number</div>
          <div>{customerUser?.customer_user?.mobileNumber || '-'}</div>
        </SBullet>
        <SBullet>
          <div>Email</div>
          <div>{customerUser?.customer_user?.email || '-'}</div>
        </SBullet>
        <SBullet>
          <div>Date of Birth</div>
          <div>
            {customerUser?.customer_user?.dob
              ? dateFormatter(customerUser?.customer_user?.dob)
              : '-'}
          </div>
        </SBullet>
        <SBullet>
          <div>Gender</div>
          <div>{customerUser?.customer_user?.gender || '-'}</div>
        </SBullet>
      </div>
      <div>
        <STopic>
          <div>Address Information</div>
          <div
            onClick={() => {
              navigate(
                `/home/customer?id=${customerUser?.customer_user?.customerId}&phone=${customerUser?.customer_user?.mobileNumber}`,
              );
              setContentSelected('customer');
            }}
          >
            <EditIcon />
          </div>
        </STopic>
        <SBullet>
          <div>Region</div>
          <div>{customerUser?.customer_address_data?.region?.nameTh || '-'}</div>
        </SBullet>
        <SBullet>
          <div>Province</div>
          <div>{customerUser?.customer_address_data?.province?.nameTh || '-'}</div>
        </SBullet>
        <SBullet>
          <div>District</div>
          <div>{customerUser?.customer_address_data?.district?.nameTh || '-'}</div>
        </SBullet>
        <SBullet>
          <div>Subdistrict</div>
          <div>{customerUser?.customer_address_data?.subdistrict?.nameTh || '-'}</div>
        </SBullet>
        <SBullet>
          <div>Postcode</div>
          <div>{customerUser?.customer_user?.postCode || '-'}</div>
        </SBullet>
      </div>
    </SContainer>
  );
};
export default ChatInformation;
const SContainer = styled.div`
  width: 100%;
`;
const STopic = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 10px 15px;
  & > div {
    &:nth-child(1) {
      font-size: 20px;
      font-weight: bold;
    }
    &:nth-child(2) {
      cursor: pointer;
    }
  }
`;
const SBullet = styled.div`
  & > div {
    padding: 0px 15px;
    &:nth-child(1) {
      color: #737373;
    }
  }
`;

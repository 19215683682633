import { instance } from './instance';
interface IGetChatList {
  tags: string[];
  status: string;
  channel: string;
  page: string;
  pagesize: string;
}
const getChatList = async ({ tags, status, channel, page, pagesize }: IGetChatList) => {
  try {
    const result = await instance.get('/chat', {
      params: { tags, status, channel, page, pagesize },
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface IGetChatSession {
  sessionid: string;
  page: string;
  pagesize: string;
}
const getChatSession = async ({ sessionid, page, pagesize }: IGetChatSession) => {
  try {
    const result = await instance.get('/message', {
      params: { sessionid, page, pagesize },
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface ISendMessage {
  sessionId: string;
  senderId: string;
  customerSocialId: number;
  content: string;
}
const sendMessage = async ({ sessionId, customerSocialId, content, senderId }: ISendMessage) => {
  try {
    const result = await instance.post('/message/agent', {
      sessionId: sessionId,
      senderId: senderId,
      customerSocialId: customerSocialId,
      senderFrom: 'AGENT',
      content: content,
      createdAt: new Date(),
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface IAdminAssign {
  sessionId: string;
  AdminId: string;
}
const adminAssign = async ({ sessionId, AdminId }: IAdminAssign) => {
  try {
    const result = await instance.post('/chat/admin-assign', {
      sessionId: sessionId,
      AdminId: AdminId,
    });
    if (result.status) {
      return true;
    } else {
      return false;
    }
  } catch (err: any) {
    return false;
  }
};
interface IAdminStartConversation {
  customerID: string;
  customerSocialChannelID: number;
}
const adminStartConversation = async ({
  customerID,
  customerSocialChannelID,
}: IAdminStartConversation) => {
  try {
    const result = await instance.post('/chat/agentStartConversation', {
      customerID,
      customerSocialChannelID,
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
interface IAdminReassign {
  newAgentID: string;
  chatSessionID: string;
}
const adminReassign = async ({ newAgentID, chatSessionID }: IAdminReassign) => {
  try {
    const result = await instance.post('/chat/agentReassign', {
      newAgentID,
      chatSessionID,
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
const apiChat = {
  getChatList,
  sendMessage,
  getChatSession,
  adminAssign,
  adminStartConversation,
  adminReassign,
};
export default apiChat;

import { create } from 'zustand';
import { io, Socket } from 'socket.io-client';
import chatStore from './ChatStore';
import { tokenAuthenticationStore, userAuthenticationStore } from './UserAuthenticationStore';
import { getNewToken } from '../apis/instance';
// Define types for your state and actions
interface IMessage {
  content: string;
  senderFrom: string;
  sessionId: string;
  customerSocialId: string;
  senderId: string;
  createdAt: string;
}

interface IReadMessage {
  sessionId: string;
  adminId: string;
}

interface IChatList {
  tags: string[];
  agentID: string;
  status: string;
  channel: string;
  page: number;
  pageSize: number;
  q?: string;
}
interface IGetMessage {
  sessionId: string;
  page: string;
  pageSize: string;
}

interface ICloseSession {
  sessionId: string;
}

interface SocketState {
  socket: Socket | null;
  logout: () => void;
  setSocket: (socket: Socket | null) => void;
  initializeSocket: (url: string) => void;
  getMessage: (message: IGetMessage) => void;
  readMessage: (message: IReadMessage) => void;
  sendMessage: (message: IMessage) => void;
  getChatList: (chatList: IChatList) => void;
  closeSession: (chat: ICloseSession) => void;
  count: number;
}

const useSocketStore = create<SocketState>((set, get) => ({
  socket: null,
  setSocket: (socket) => set({ socket }),
  count: 0,
  logout: () => {
    const { socket } = get();
    if (socket) {
      socket.disconnect();
      socket.off('response_session_assigned');
      socket.off('customer_message');
      socket.off('admin_message');
      socket.off('response_chat_list_by_tags');
      socket.off('new_chat');
      socket.off('response_get_message');
      socket.off('response_admin_close_session');
      socket.off('error');
      socket.off();
      set({ socket: null });
      console.log('Logged out and disconnected from socket.');
    }
  },
  initializeSocket: (url) => {
    const newSocket = io(url, {
      extraHeaders: {
        Authorization: `Bearer ${tokenAuthenticationStore.getState().token}`,
      },
      reconnection: true,
      // timeout: 1000,
      reconnectionDelay: 1000,
      reconnectionAttempts: Infinity,
    });
    const socketTimeout = setTimeout(() => {
      newSocket.off('response_session_assigned');
      newSocket.off('customer_message');
      newSocket.off('admin_message');
      newSocket.off('response_chat_list_by_tags');
      newSocket.off('new_chat');
      newSocket.off('response_get_message');
      newSocket.off('response_admin_close_session');
      newSocket.off('error');
      newSocket.disconnect();
      window.location.replace(`${process.env.REACT_APP_FRONTEND_URL}/login`);
    }, 3000);
    newSocket.on('connect', () => {
      clearInterval(socketTimeout);
    });
    newSocket.on('customer_message', (message) => {
      console.log('trigger customer_message:', message);
      chatStore.getState().getNewChatSession([message]);
      chatStore.getState().updateLastMessage(message);
    });
    newSocket.on('error', async (res) => {
      console.log('trigger error: ', res);
      const newToken = await getNewToken();
      if (!newToken.token) {
        userAuthenticationStore.getState().handleLogout();
        tokenAuthenticationStore.getState().handleLogout();
        window.location.replace(`${process.env.REACT_APP_FRONTEND_URL}/login`);
      } else {
        newSocket.connect();
      }
    });
    newSocket.on('response_chat_list_by_tags', (res) => {
      console.log('trigger response_chat_list_by_tags:', res);
      if (res.pagination.currentPage === 1) {
        chatStore.getState().setAllChatList(res);
      } else {
        chatStore.getState().addChatList(res);
      }
    });
    newSocket.on('response_admin_close_session', (res) => {
      console.log('trigger response_admin_close_session:', res);
      chatStore.getState().removeChatListBySessionId(res.sessionId);
    });
    newSocket.on('new_chat', (res) => {
      console.log('trigger new_chat:', res);
      chatStore.getState().addNewChatList(res);
    });
    newSocket.on('admin_message', (message) => {
      console.log('trigger admin_message:', message);
      chatStore.getState().getNewChatSession([message]);
      chatStore.getState().updateLastMessage(message);
    });
    newSocket.on('response_get_message', (res) => {
      console.log('trigger response_get_message:', res);
      chatStore.getState().setChatSessionTotalPage(res.pagination);
      if (!chatStore.getState().chatSession.length) {
        chatStore.getState().setChatSession(res.contents.messages.reverse());
        chatStore.getState().setCustomerDetails(res.contents.customerDetails);
      } else {
        chatStore.getState().getHistoryChatSession(res.contents.messages.reverse());
      }
    });
    newSocket.on('response_session_assigned', (res) => {
      console.log('trigger response_session_assigned:', res);
      chatStore.getState().takeSession(res);
    });

    set({ socket: newSocket });

    return () => {
      clearInterval(socketTimeout);
      newSocket.off('response_session_assigned');
      newSocket.off('customer_message');
      newSocket.off('admin_message');
      newSocket.off('response_chat_list_by_tags');
      newSocket.off('new_chat');
      newSocket.off('response_get_message');
      newSocket.off('response_admin_close_session');
      newSocket.off('error');
      newSocket.disconnect();
    };
  },
  getMessage: ({ sessionId, page, pageSize }) => {
    console.log('emit get_message:');
    const socket = get().socket;
    if (socket) {
      socket.emit('get_message', {
        sessionId,
        page,
        pageSize,
      });
    }
  },
  readMessage: ({ sessionId, adminId }) => {
    console.log('emit admin_read_message:');
    const socket = get().socket;
    if (socket) {
      socket.emit('admin_read_message', {
        sessionId,
        adminId,
        readAt: new Date().toISOString(),
      });
    }
  },
  sendMessage: async (message) => {
    console.log('emit admin_sent_message:');
    try {
      const socket = get().socket;
      if (socket) {
        await socket.emit('admin_sent_message', message);
        await chatStore.getState().updateLastMessage({
          senderFrom: 'AGENT',
          content: message.content,
          sessionId: message.sessionId,
          newMessageCount: 0,
        });
        await chatStore
          .getState()
          .addSendChatSession([
            { senderFrom: 'AGENT', content: message.content, createdAt: new Date().toISOString() },
          ]);
        return true;
      }
    } catch (error) {
      console.log('error: ', error);
    }
  },
  closeSession: ({ sessionId }) => {
    console.log('emit admin_close_session:', {
      sessionId,
    });
    const socket = get().socket;
    if (socket) {
      socket.emit('admin_close_session', {
        sessionId,
      });
    }
  },
  getChatList: ({ tags, agentID, status, channel, page, pageSize, q }) => {
    console.log('emit get_chat_list_by_tags:', {
      tags,
      agentID,
      status,
      channel,
      page,
      pageSize,
      q,
    });
    const socket = get().socket;
    if (socket) {
      socket.emit('get_chat_list_by_tags', {
        tags,
        agentID,
        status,
        channel,
        page,
        pageSize,
        q,
      });
    }
  },
}));

export default useSocketStore;

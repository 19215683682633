import styled from 'styled-components';
import loginImage from '../../assets/login/login-image-1.jpeg';
import mainLogo from '../../assets/main-logo.png';
interface ILayout {
  children: JSX.Element;
}
const Layout = ({ children }: ILayout) => {
  return (
    <SContainer>
      <SLoginContainer>
        <SLogoContainer>
          <img src={mainLogo} alt="login" />
        </SLogoContainer>
        {children}
      </SLoginContainer>
      <SImgContainer>
        <img src={loginImage} alt="login" />
      </SImgContainer>
    </SContainer>
  );
};
export default Layout;
const SContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template: 1fr/1fr 1fr;
`;
const SImgContainer = styled.div`
  width: 100%;
  border-radius: 32px 0px 0px 32px;
  overflow: hidden;
  & > img {
    width: 100%;
    min-height: 100%;
  }
`;
const SLoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const SLogoContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 50px;
`;

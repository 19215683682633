import styled from 'styled-components';
interface ILayout {
  children: JSX.Element;
}
const Layout = ({ children }: ILayout) => {
  return <SContainer>{children}</SContainer>;
};
export default Layout;
const SContainer = styled.div`
  .table-row-light {
    background-color: #f9f9f9 !important;
    & > td {
      background-color: #f9f9f9 !important;
    }
  }

  .table-row-dark {
    background-color: #ffffff !important;
    & > td {
      background-color: #ffffff !important;
    }
  }
  .ant-table-cell .ant-table-cell-fix-left,
  .ant-table-cell .ant-table-cell-fix-right {
    background-color: inherit !important; /* Inherit the row's background color */
  }
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  & > div {
    height: fit-content;
    width: 95%;
    height: 100%;
    overflow-y: scroll;
  }
`;

import styled from 'styled-components';
import Layout from './Layout.login';
import { Button, Form, Input } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import apiLogin from '../../apis/login';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [requestIsAllow, setRequestIsAllow] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const { uuid } = useParams();
  const onFinish = (values: any) => {
    if (!values.password || !values.confirmPassword) {
      setIsWrong(true);
      return;
    }
    if (values.password !== values.confirmPassword) {
      setIsWrong(true);
    } else {
      setIsWrong(false);
      if (uuid) {
        apiLogin.changePassword(uuid, values.password);
        navigate(`/login`);
      }
    }
  };
  const handleCheckRequest = async () => {
    if (uuid) {
      const result = await apiLogin.checkChangePasswordRequest(uuid);
      setRequestIsAllow(result);
    }
  };
  useEffect(() => {
    handleCheckRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      {requestIsAllow ? (
        <>
          <SHeader>
            <div className="topic">Change password</div>
            <div className="sup-topic">Change your password for added security.</div>
          </SHeader>
          <SInputContainer>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item<string>
                label="New password"
                name="password"
                required
                validateStatus={isWrong ? 'error' : ''}
              >
                <Input.Password placeholder="Enter your password" />
              </Form.Item>
              <Form.Item<string>
                label="Confirm password"
                name="confirmPassword"
                required
                validateStatus={isWrong ? 'error' : ''}
              >
                <Input.Password placeholder="Confirm your password" />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: '100%',
                    background: '#001689',
                    color: 'white',
                  }}
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </SInputContainer>
        </>
      ) : (
        <>
          <SHeader>
            <div className="topic">Request Timeout</div>
            <div className="sup-topic">Please redo forgot password.</div>
          </SHeader>
        </>
      )}
    </Layout>
  );
};
export default ChangePassword;
const SHeader = styled.div`
  width: 70%;
  margin-bottom: 30px;
  & > div {
    &.back {
      cursor: pointer;
      border-radius: var(--md, 8px);
      border: 1px solid var(--border-border-primary, #d9d9d9);
      background: var(--surface-surface-primary, #fff);
      width: fit-content;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
      padding: 10px;
    }
    &.topic {
      font-weight: 500;
      line-height: 40px;
      font-size: 30px;
      margin-top: 20px;
    }
    &.sup-topic {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #737373;
    }
  }
`;
const SInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  .login-form {
    max-width: 300px;
  }
  .login-form-forgot {
    float: right;
  }
`;

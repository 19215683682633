import styled from 'styled-components';
import { Button, Checkbox, Form, Input } from 'antd';
import Layout from './Layout.login';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  tokenAuthenticationStore,
  userAuthenticationStore,
} from '../../contexts/UserAuthenticationStore';
import loadingStore from '../../contexts/LoadingStore';
import Loading from '../../components/Loading';
const Login = () => {
  const navigate = useNavigate();
  const { turnOnLoading, turnOffLoading, isLoading } = loadingStore();
  const [isWrong, setIsWrong] = useState(false);
  const handleLogin = userAuthenticationStore((state) => state.handleLogin);
  const handleToken = tokenAuthenticationStore((state) => state.handleLogin);
  const onFinish = async (values: any) => {
    turnOnLoading();
    const userLogin = await handleLogin(values.email, values.password);
    if (userLogin.refreshToken) {
      const userToken = await handleToken(userLogin.token, userLogin.refreshToken);
      if (userToken) {
        setIsWrong(false);
        navigate('/home/default');
      } else {
        setIsWrong(true);
      }
    } else {
      setIsWrong(true);
    }
    turnOffLoading();
  };

  return (
    <Layout>
      <>
        {isLoading && <Loading />}
        <SHeader>
          <div className="topic">Welcome</div>
          <div className="sup-topic">Please login first before you use this website.</div>
        </SHeader>
        <SInputContainer>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            style={{ width: '100%' }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item<string>
              label="Email"
              name="email"
              validateStatus={isWrong ? 'error' : ''}
              help={isWrong ? 'Email or password is wrong.' : ''}
              required
            >
              <Input placeholder="Enter your email" status={isWrong ? 'error' : ''} />
            </Form.Item>
            <Form.Item<string> label="Password" name="password" required>
              <Input.Password placeholder="Enter your password" status={isWrong ? 'error' : ''} />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember </Checkbox>
              </Form.Item>

              <SLink
                className="login-form-forgot"
                onClick={() => {
                  navigate('/forgot');
                }}
              >
                Forgot password?
              </SLink>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%', background: '#001689', color: 'white' }}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </SInputContainer>
      </>
    </Layout>
  );
};
export default Login;
const SLink = styled.div`
  color: black;
  text-decoration: underline;
  cursor: pointer;
`;
const SInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  .login-form {
    max-width: 300px;
  }
  .login-form-forgot {
    float: right;
  }
`;
const SHeader = styled.div`
  width: 70%;
  margin-bottom: 30px;
  & > div {
    &.topic {
      font-weight: 500;
      line-height: 40px;
      font-size: 30px;
    }
    &.sup-topic {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #737373;
    }
  }
`;

import styled from 'styled-components';
import { ReactComponent as ReassignIcon } from '../../../../assets/reassign.svg';
import Search, { SearchProps } from 'antd/es/input/Search';
import { Button, Radio, Table, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { IAgent } from '../../../../interfaces/agent.interface';
import { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import apiAgent from '../../../../apis/agent';
import ResizableTitle from '../../../ResizeableTitle';
import apiChat from '../../../../apis/chat';
import { useNavigate } from 'react-router-dom';
import pageStore from '../../../../contexts/PageStore';

interface IReAssignPopup {
  setOpenPopupReAssign: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSessionId: string;
}
const ReAssignPopup = ({ setOpenPopupReAssign, selectedSessionId }: IReAssignPopup) => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<IAgent[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { setContentSelected } = pageStore();

  const onSearch = (value: string) => {
    setSearchQuery(value);
  };
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);

  const radioSelectRef = useRef<any>();
  const handleSelect = (key: string) => {
    setSelectedRowKey(key);
    radioSelectRef.current = key;
  };
  const [columns, setColumns] = useState<ColumnsType<IAgent>>([
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 50,
      render: (text, record) => {
        return (
          <Radio
            checked={radioSelectRef.current === record.id}
            onChange={() => handleSelect(record.id)}
          />
        );
      },
      fixed: 'left',
    },
    {
      title: 'name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 300,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (text, record) => <div>{`${record.firstName} ${record.lastName}`}</div>,
      fixed: 'left',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      width: 200,
      render: (text) => {
        if (text) {
          return <SChannel className="channel">{text}</SChannel>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      width: 200,
      render: (text) => {
        if (text) {
          return <SChannel className="language">{text}</SChannel>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      width: 200,
      render: (text) => {
        if (text) {
          return (
            <Tooltip title={text}>
              <SChannel style={{ whiteSpace: 'nowrap' }} className="department">
                {text.length > 17 ? `${text.slice(0, 17)}...` : text}
              </SChannel>
            </Tooltip>
          );
        } else {
          return <></>;
        }
      },
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 200,
      render: (text) => {
        if (text) {
          return <SChannel className="role">{text}</SChannel>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 300,
      render: (text) => (
        <STagsContainer style={{ display: 'flex', flexWrap: 'wrap' }}>
          {text.map((e: string, i: number) => {
            return (
              <Tooltip title={e}>
                <div style={{ whiteSpace: 'nowrap' }}>
                  {e.length > 31 ? `${e.slice(0, 31)}...` : e}
                </div>
              </Tooltip>
            );
          })}
        </STagsContainer>
      ),
    },
  ]);
  const {
    data: agentListData,
    error: agentListError,
    isLoading: agentListIsLoading,
    isFetching: agentListIsFetching,
  } = useQuery({
    queryKey: ['allAgent', '1', searchQuery],
    queryFn: async () => {
      return apiAgent.getAgentList({
        page: 1,
        pageSize: `200`,
        q: searchQuery,
      });
    },
  });
  const handleConvertData = async () => {
    if (agentListData?.data) {
      const result: IAgent[] = [];
      for (let i = 0; i < agentListData.data.length; i++) {
        const agentData: IAgent = agentListData.data[i];
        result.push({
          id: agentData.id,
          firstName: agentData.firstName,
          lastName: agentData.lastName,
          email: agentData.email,
          phoneNumber: agentData.phoneNumber,
          channel: agentData.channel,
          channelID: agentData.channelID,
          language: agentData.language,
          languageID: agentData.languageID,
          department: agentData.department,
          departmentID: agentData.departmentID,
          role: agentData.role,
          roleID: agentData.roleID,
          tags: agentData.tags,
        });
      }

      setDataSource(result);
    } else {
      setDataSource([]);
    }
  };
  useEffect(() => {
    handleConvertData();
  }, [agentListData?.data]);
  const handleResize =
    (index: number) =>
    (e: React.SyntheticEvent, { size }: any) => {
      setColumns((prevColumns) => {
        const newColumns = [...prevColumns];
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
        };
        return newColumns;
      });
    };

  const mergedColumns: any = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));
  const handleReassign = async () => {
    if (!selectedRowKey) return;
    await apiChat.adminReassign({
      newAgentID: selectedRowKey,
      chatSessionID: selectedSessionId,
    });
    setOpenPopupReAssign(false);
    navigate('/home/chat_room');
    setContentSelected('chat_room');
    window.location.reload();
  };
  return (
    <SContainer>
      <SContent>
        <div style={{ display: 'flex' }}>
          <div>
            <ReassignIcon />
          </div>
          <div style={{ fontWeight: 'bold', marginLeft: '5px' }}>Reassign</div>
        </div>
        <div style={{ fontSize: '12px', color: '#737373' }}>
          Please select an agent from the list below to reassign to.
        </div>
        <div style={{ marginTop: '10px' }}>
          <Search placeholder="Search" onChange={(e) => onSearch(e.target.value)} />
        </div>
        <div style={{ marginTop: '10px' }}>
          <Table
            className="custom-table"
            dataSource={dataSource}
            columns={mergedColumns}
            pagination={false}
            rowKey={'serialNumber'}
            scroll={{ x: 1500, y: 350 }}
            components={{
              header: {
                cell: ResizableTitle,
              },
            }}
            rowClassName={(record, index) =>
              index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
            }
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div>
            <Button onClick={() => setOpenPopupReAssign(false)}>Cancel</Button>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <Button
              disabled={selectedRowKey ? false : true}
              onClick={() => {
                handleReassign();
              }}
            >
              Assign
            </Button>
          </div>
        </div>
      </SContent>
    </SContainer>
  );
};
export default ReAssignPopup;
const SContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SContent = styled.div`
  background-color: white;
  width: 70vw;
  overflow: scroll;
  border-radius: 32px;
  height: 60vh;
  padding: 20px;
`;
const SChannel = styled.div`
  width: fit-content;
  border: 1px solid;
  border-radius: 20px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
  padding: 0px 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  &.channel {
    border-color: #06c755;
    background-color: #dcfae6;
  }
  &.language {
    border-color: #0866ff;
    background-color: #e6f0ff;
  }
  &.department {
    border-color: #f79009;
    background-color: #fffaeb;
  }
  &.role {
    border-color: #9409f7;
    background-color: #fcebff;
  }
`;
const STagsContainer = styled.div`
  & > div {
    border: solid 1px;
    padding: 1px 10px;
    margin: 5px 5px;
    border-radius: 50px;
    &:nth-child(1) {
      border-color: #06c755;
      background-color: #dcfae6;
    }
    &:nth-child(2) {
      border-color: #f79009;
      background-color: #fffaeb;
    }
    &:nth-child(3) {
      border-color: #0866ff;
      background-color: #e6f0ff;
    }
  }
`;

import { instance } from './instance';

const getDistrictListByPostCode = async (postCode: string | number) => {
  try {
    const result = await instance.get(`/customer/getDistrictListByPostCode/${postCode}`);
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
const getSubDistrictListByPostCodeAndDistrict = async ({
  districtID,
  postCode,
}: {
  districtID: string;
  postCode: string;
}) => {
  try {
    const result = await instance.get(`/customer/getAddressFromParams`, {
      params: { districtID, postCode },
    });
    return result.data;
  } catch (err: any) {
    return undefined;
  }
};
const apiAddress = {
  getDistrictListByPostCode,
  getSubDistrictListByPostCodeAndDistrict,
};
export default apiAddress;

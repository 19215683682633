import styled from 'styled-components';
import { ReactComponent as LoadingAnimate } from '../assets/loading.svg';
const Loading = () => {
  return (
    <SContainer>
      <LoadingAnimate />
    </SContainer>
  );
};
export default Loading;
const SContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

import styled from 'styled-components';
import Search from 'antd/es/input/Search';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as CreateButton } from '../../../../assets/create.svg';
import { useQuery } from '@tanstack/react-query';
import apiWarrantyRegistration from '../../../../apis/warrantyRegistration';
import { Dropdown, Table, Image, Tooltip, MenuProps } from 'antd';
import loadingStore from '../../../../contexts/LoadingStore';
import {
  tokenAuthenticationStore,
  userAuthenticationStore,
} from '../../../../contexts/UserAuthenticationStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { HookAPI } from 'antd/es/modal/useModal';
import { ColumnsType } from 'antd/es/table';
import defaultAvatar from '../../../../assets/default-avatar.jpg';
import { dateFormatter } from '../../../../utils/format';
import { ReactComponent as Slip } from '../../../../assets/home/slip.svg';
import { ReactComponent as Line } from '../../../../assets/channel/line.svg';
import { ReactComponent as Facebook } from '../../../../assets/channel/facebook.svg';
import { ReactComponent as Widget } from '../../../../assets/channel/widget.svg';
import { ReactComponent as ThreeDot } from '../../../../assets/threedot.svg';
import { ReactComponent as Edit } from '../../../../assets/edit.svg';
import { IRegistrationRecord } from '../../../../interfaces/warrantyRegistration.interface';
import ResizableTitle from '../../../ResizeableTitle';
import { ReactComponent as Bin } from '../../../../assets/bin.svg';
interface IList {
  setContentState: React.Dispatch<React.SetStateAction<'table' | 'create' | 'edit'>>;
  modal: HookAPI;
  setSelectedRecord: React.Dispatch<React.SetStateAction<IRegistrationRecord | undefined>>;
}
interface DataType {
  customer: string;
  customerImage: string;
  serialNumber: string;
  productName: string;
  phoneNumber: string;
  registrationDate: string;
  expiredDate: string;
  slip: string;
  platform: string;
  channel: string;
  campaignHashtag: string;
  status: string;
  campaignActionBy: string | null;
  slipPath: string;
  ref: IRegistrationRecord;
}
const PAGE_SIZE = 9;
const List = ({ setContentState, modal, setSelectedRecord }: IList) => {
  const { resetAuthState, adminId, role } = userAuthenticationStore((state) => {
    return { resetAuthState: state.resetAuthState, adminId: state.id, role: state.role };
  });
  const navigate = useNavigate();
  const { turnOnLoading, turnOffLoading } = loadingStore();
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const dataSourceRef = useRef<DataType[]>();
  const [searchValue, setSearchValue] = useState<string>('');
  const resetTokenState = tokenAuthenticationStore((state) => state.resetTokenState);
  const [pageNo, setPageNo] = useState<number>(1);
  const [searchValueApi, setSearchValueApi] = useState<string>('');
  const handleTyping = (value: string) => {
    setSearchValue(value);
    setPageNo(1);
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [totalList, setTotalList] = useState(0);
  useEffect(() => {
    if (id) {
      setContentState('create');
    }
  }, [id]);
  const items: MenuProps['items'] = [
    {
      label: 'Incompleted',
      key: 'Incompleted',
    },
    {
      label: 'Pending',
      key: 'Pending',
    },
    {
      label: 'Rejected',
      key: 'Rejected',
    },
    {
      label: 'Approved',
      key: 'Approved',
    },
  ];
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchValueApi(searchValue);
    }, 1500); // delay 1.5 seconds

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [searchValue]);
  const {
    data: warrantyListData,
    error: warrantyListError,
    isLoading: warrantyListIsLoading,
    isFetching: warrantyListIsFetching,
  } = useQuery<{ data: IRegistrationRecord[]; total: number }>({
    queryKey: ['allChatList', searchValueApi, pageNo],
    queryFn: async () => {
      return apiWarrantyRegistration.getList({
        query: searchValue,
        pageNo: pageNo,
        pageSize: PAGE_SIZE,
      });
    },
  });
  useEffect(() => {
    if (warrantyListIsLoading || warrantyListIsFetching) {
      turnOnLoading();
    } else {
      turnOffLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warrantyListIsLoading, warrantyListIsFetching]);
  useEffect(() => {
    if (!warrantyListError) return;
    turnOffLoading();
    modal.error({
      title: `Session Timeout.`,
      content: <>Please login again.</>,
      onOk() {
        resetAuthState();
        resetTokenState();
        navigate('/login');
      },
      okText: 'Login',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warrantyListError]);
  const handleConvertData = async () => {
    if (warrantyListData?.data) {
      setTotalList(warrantyListData.total);
      const result: DataType[] = [];
      for (let i = 0; i < warrantyListData.data.length; i++) {
        result.push({
          customer: warrantyListData.data[i].customer.fullName,
          customerImage: warrantyListData.data[i]?.social_channel?.imageUrl || '',
          serialNumber: warrantyListData.data[i].serialNumber,
          productName: warrantyListData.data[i].productName || '',
          phoneNumber: warrantyListData.data[i].customer.mobileNumber,
          registrationDate: warrantyListData.data[i].registeredAt,
          expiredDate: warrantyListData.data[i].expiredAt,
          slip: warrantyListData.data[i].serialNumber,
          platform: warrantyListData.data[i]?.social_channel?.channel || '',
          channel: warrantyListData.data[i]?.social_channel?.subChannel || '',
          campaignHashtag: warrantyListData.data[i].campaignName || '',
          status: warrantyListData.data[i].status,
          campaignActionBy: warrantyListData.data[i].campaignActionBy,
          slipPath: warrantyListData.data[i].slipPath,
          ref: warrantyListData.data[i],
        });
      }

      setDataSource(result);
      dataSourceRef.current = result;
    } else {
      setTotalList(0);
      setDataSource([]);
      dataSourceRef.current = [];
    }
  };
  useEffect(() => {
    handleConvertData();
  }, [warrantyListData?.data]);
  const handleTableChange = (newPagination: number) => {
    setPageNo(newPagination);
  };
  const [columns, setColumns] = useState<ColumnsType<DataType>>([
    {
      title: 'No',
      key: 'no',
      render: (text, record, index) => (
        <div className="no">{(pageNo - 1) * PAGE_SIZE + index + 1}</div>
      ),
      width: 70,
      ellipsis: true,
      fixed: 'left',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      width: 180,
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      render: (text, record) => (
        <div className="customer" style={{ display: 'flex', width: '100%' }}>
          <div>
            <img src={record.customerImage || defaultAvatar} alt="display" />
          </div>
          <div>{text}</div>
        </div>
      ),

      // ellipsis: true,
      fixed: 'left',
    },
    {
      title: 'Serial number',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
      fixed: 'left',
      width: 180,
      // ellipsis: true,
    },
    {
      title: 'Product name',
      dataIndex: 'productName',
      key: 'productName',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      width: 190,
      // ellipsis: true,
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Registration date',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      sorter: (a, b) => a.registrationDate.localeCompare(b.registrationDate),
      render: (text) => {
        return <div>{dateFormatter(text)}</div>;
      },

      width: 170,
      ellipsis: true,
    },
    {
      title: 'Expired date',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      sorter: (a, b) => a.expiredDate.localeCompare(b.expiredDate),
      render: (text) => {
        return <div>{dateFormatter(text)}</div>;
      },

      width: 170,
      ellipsis: true,
    },
    {
      title: 'Slip',
      dataIndex: 'slip',
      key: 'slip',
      width: 90,
      render: (text, record) => {
        if (record.slipPath) {
          return (
            <div
              className="slip"
              onClick={async () => {
                const slipAttach = await apiWarrantyRegistration.attachSlip({
                  serialNumber: text,
                });
                await modal.info({
                  title: 'Slip Image',
                  content: (
                    <div style={{ width: '100%' }}>
                      <Image width={'100%'} src={slipAttach} />
                    </div>
                  ),
                  //   footer: null,
                  icon: null,
                  cancelButtonProps: {
                    disabled: true,
                  },
                });
              }}
            >
              <Slip />
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },

      ellipsis: true,
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      sorter: (a, b) => a.channel.localeCompare(b.channel),
      render: (text, record) => {
        switch (record.platform.toLowerCase()) {
          case 'line':
            return (
              <div className="channel line">
                <div>
                  <Line />
                </div>
                <div>{text}</div>
              </div>
            );
          case 'facebook_messenger':
            return (
              <div className="channel facebook_messenger">
                <div>
                  <Facebook />
                </div>
                <div>{text}</div>
              </div>
            );
          case 'web_widget':
            return (
              <div className="channel widget">
                <div>
                  <Widget />
                </div>
                <div>{text}</div>
              </div>
            );
        }
      },
      width: 180,
      ellipsis: true,
    },
    {
      title: 'Campaign Hashtag',
      dataIndex: 'campaignHashtag',
      key: 'campaignHashtag',
      sorter: (a, b) => a.campaignHashtag.localeCompare(b.campaignHashtag),
      render: (text, record) =>
        text && (
          <Tooltip title={text}>
            <div className="hashtag">{text.length > 31 ? `${text.slice(0, 31)}...` : text}</div>
          </Tooltip>
        ),
      width: 300,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => {
        if (record.platform === 'WEB_WIDGET') {
          return 'web-widget';
        }
        if (record.campaignHashtag) {
          switch (text.toLowerCase()) {
            case 'approved':
              return <div className="status approved">{text}</div>;
            case 'rejected':
              return <div className="status rejected">{text}</div>;
            case 'pending':
              return <div className="status pending">{text}</div>;
            case 'incompleted':
              return <div className="status inCompleted">{text}</div>;
          }
        } else {
          return <div></div>;
        }
      },
      width: 150,
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 90,
      render: (text, record, index) => {
        if (record.campaignHashtag && record.platform !== 'WEB_WIDGET') {
          const onClick: MenuProps['onClick'] = (e: any) => {
            if (e.key.toLowerCase() !== 'approved' && e.key.toLowerCase() !== 'rejected') {
              apiWarrantyRegistration.changeStatus({
                serialNumber: dataSource[index].serialNumber,
                status: e.key,
                actionBy: adminId,
              });
              const changeDataSource = [...dataSource];
              changeDataSource[index].status = e.key;
              setDataSource(changeDataSource);
              dataSourceRef.current = changeDataSource;
            } else {
              modal.confirm({
                title: `Status message cannot be changed after confirm.`,
                content: (
                  <>{`You are changing the Serial number ${record.serialNumber} from "${record.status}" to "${e.key}"`}</>
                ),
                onOk() {
                  if (!dataSourceRef?.current?.length) return;
                  apiWarrantyRegistration.changeStatus({
                    serialNumber: dataSourceRef.current[index].serialNumber,
                    status: e.key,
                    actionBy: adminId,
                  });
                  const changeDataSource = [...dataSourceRef.current];
                  changeDataSource[index].status = e.key;
                  setDataSource(changeDataSource);
                  dataSourceRef.current = changeDataSource;
                },
                onCancel() {},
                cancelText: 'Cancel',
                okText: 'Confirm',
              });
            }
          };
          if (
            record.status.toLowerCase() === 'approved' ||
            record.status.toLowerCase() === 'rejected'
          ) {
            return <div className="option"></div>;
          } else {
            return (
              <div className="option">
                <Dropdown menu={{ items, onClick }} trigger={['click']}>
                  <ThreeDot />
                </Dropdown>
              </div>
            );
          }
        }
      },
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 90,
      render: (text, record, index) => {
        const onClickEdit = () => {
          setSelectedRecord(record.ref);
          setContentState('edit');
        };
        const onClickDelete = () => {
          modal.confirm({
            title: `Confirm Deletion.`,
            content: (
              <>{`Are you sure you want to delete serial number ${record.serialNumber} with status ${record.status}? This action cannot be undone.`}</>
            ),
            onOk: async () => {
              if (!dataSourceRef.current) return;
              const result = await apiWarrantyRegistration.deleteSerialNumber(record.serialNumber);
              if (result === 'success') {
                const changeDataSource = dataSourceRef.current.filter((e) => {
                  return e.serialNumber !== record.serialNumber;
                });
                setTotalList((prev) => prev - 1);
                setDataSource(changeDataSource);
                dataSourceRef.current = changeDataSource;
              }
            },
            onCancel() {},
            cancelText: 'Cancel',
            okText: 'Confirm',
          });
        };
        return (
          <div style={{ display: 'flex' }}>
            <div className="option" onClick={() => onClickEdit()}>
              <Edit />
            </div>
            {role === 'Administrator' && (
              <div className="option" style={{ marginLeft: '5px' }} onClick={() => onClickDelete()}>
                <Bin />
              </div>
            )}
          </div>
        );
      },
      ellipsis: true,
      fixed: 'right',
    },
  ]);
  const handleResize =
    (index: number) =>
    (e: React.SyntheticEvent, { size }: any) => {
      setColumns((prevColumns) => {
        const newColumns = [...prevColumns];
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
        };
        return newColumns;
      });
    };

  const mergedColumns: any = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));
  return (
    <>
      <SHeader>
        <div>
          <div style={{ fontSize: '20px', fontWeight: 700 }}>Warranty Registration</div>
          <div
            style={{ fontSize: '12px', fontWeight: 400 }}
          >{`จำนวนทั้งหมด ${totalList} รายการ`}</div>
        </div>
        <div className="searchAndCreate">
          <div style={{ width: '70%' }}>
            <Search
              placeholder="Search"
              value={searchValue}
              width={'100%'}
              onChange={(e) => handleTyping(e.target.value)}
            />
          </div>
          <div
            style={{ width: '30%' }}
            onClick={() => {
              setContentState('create');
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CreateButton />
            </div>
            <div>Create warranty</div>
          </div>
        </div>
      </SHeader>
      {warrantyListData?.total && (
        <Table
          className="custom-table"
          dataSource={dataSource}
          columns={mergedColumns}
          pagination={{
            current: pageNo,
            pageSize: PAGE_SIZE,
            total: warrantyListData.total,
            showSizeChanger: false,
          }}
          rowKey={'serialNumber'}
          scroll={{ x: 1500 }}
          onChange={(e) => e.current && handleTableChange(e.current)}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        />
      )}
    </>
  );
};

export default List;
const SHeader = styled.div`
  display: grid;
  grid-template: 1fr/4fr 3fr;
  .searchAndCreate {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > div {
      &:nth-child(2) {
        height: 80%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 4px;
        margin-left: 10px;
        border-radius: 8px;
        color: white;
        background-color: #001689;
        & > div:nth-child(1) {
          margin-right: 5px;
        }
      }
    }
  }
`;

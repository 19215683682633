import React, { useEffect, useState } from 'react';
import { Table, Button, Select, Input, Popconfirm, Tooltip } from 'antd';
import chatStore from '../../../contexts/ChatStore';
import manageAgentStore from '../../../contexts/ManageAgentStore';
import { IQuickReply } from '../../../interfaces/agent.interface';
import apiAgent from '../../../apis/agent';
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/bin.svg';
import Layout from './Layout';
import styled from 'styled-components';
import { ReactComponent as QuickReplyIcon } from '../../../assets/quick-reply.svg';
import Search from 'antd/es/input/Search';
import { useQuery } from '@tanstack/react-query';
import TextArea from 'antd/es/input/TextArea';
import loadingStore from '../../../contexts/LoadingStore';

const { Option } = Select;
const PAGE_SIZE = 10;
const AgentQuickReply: React.FC = () => {
  const { turnOffLoading, turnOnLoading } = loadingStore();
  const { quickReplyList, setQuickReplyList } = chatStore(); // Get quickReplyList from Zustand store
  const { agentConstantContent } = manageAgentStore(); // Get agentConstantContent from Zustand store
  const [searchValue, setSearchValue] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(1);
  const [newPreview, setNewPreview] = useState<string>('');
  const [newFullMessage, setNewFullMessage] = useState<string>('');
  const [newDepartment, setNewDepartment] = useState<number[]>([]);
  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState<'create' | 'edit' | undefined>(
    undefined,
  );
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

  useEffect(() => {
    apiAgent.getAgentConstantContent();
  }, []);
  const {
    data: quickReplyListData,
    error: quickReplyListError,
    isLoading: quickReplyListIsLoading,
    isFetching: quickReplyListIsFetching,
  } = useQuery<{ quickReplies: IQuickReply[]; total: number }>({
    queryKey: ['allChatList', searchValue, pageNo],
    queryFn: async () => {
      return apiAgent.getQuickReply({
        q: searchValue,
        page: `${pageNo}`,
        pageSize: `${PAGE_SIZE}`,
      });
    },
  });
  useEffect(() => {
    setQuickReplyList(quickReplyListData?.quickReplies || []);
  }, [quickReplyListData]);

  const save = async () => {
    if (!agentConstantContent) return;
    if (isOpenCreatePopup === 'create') {
      const created = await apiAgent.createQuickReply({
        abbrMsg: newPreview,
        fullMsg: newFullMessage,
        departments: newDepartment.map((e) => e),
      });

      const newRow = {
        ...created.data,
        departments: created.data.departments.map((e: number) => {
          const result = agentConstantContent.departments.filter((el) => e === el.id);
          return result[0];
        }),
      };
      const updatedQuickReplyList = [newRow, ...quickReplyList];
      setQuickReplyList(updatedQuickReplyList);
    } else if (isOpenCreatePopup === 'edit') {
      if (!selectedId) return;
      if (!newDepartment.length) return;
      let result = quickReplyList.filter((e) => e.id === selectedId)[0];
      result = {
        ...result,
        departments: agentConstantContent.departments.filter((dept) =>
          newDepartment.includes(dept.id),
        ),
      };

      await apiAgent.editQuickReply({
        id: selectedId,
        data: {
          abbrMsg: newPreview,
          fullMsg: newFullMessage,
          departments: result.departments.map((e) => e.id),
        },
      });

      turnOnLoading();
      const waitingUpdate = setInterval(async () => {
        const newFetch = await apiAgent.getQuickReply({
          q: searchValue,
          page: `${pageNo}`,
          pageSize: `${PAGE_SIZE}`,
        });
        setQuickReplyList(newFetch?.quickReplies || []);
        turnOffLoading();
        clearInterval(waitingUpdate);
      }, 1000);
    }
  };

  const addNewRow = async () => {
    setIsOpenCreatePopup('create');
  };
  const deleteRow = (id: number) => {
    const updatedQuickReplyList = quickReplyList.filter((item) => item.id !== id);
    setQuickReplyList(updatedQuickReplyList); // Update Zustand store
    apiAgent.deleteQuickReply(id); // Call API to delete from backend (if applicable)
  };
  const columns = [
    {
      title: 'Tag',
      dataIndex: 'abbrMsg',
      width: '10%',
      render: (_: any, record: IQuickReply) => {
        return record.abbrMsg;
      },
    },
    {
      title: 'Full Message',
      dataIndex: 'fullMsg',
      width: '50%',
      render: (_: any, record: IQuickReply) => {
        return record.fullMsg;
      },
    },
    {
      title: 'Departments',
      dataIndex: 'departments',
      width: '30%',
      render: (_: any, record: IQuickReply) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {record.departments.slice(0, 2).map((e) => {
              return (
                <Tooltip title={`[${e.agentChannelID}] ${e.name}`}>
                  <SDepartmentShip>
                    {`[${e.agentChannelID}] ${e.name}`.length > 31
                      ? `${`[${e.agentChannelID}] ${e.name}`.slice(0, 31)}...`
                      : `[${e.agentChannelID}] ${e.name}`}
                  </SDepartmentShip>
                </Tooltip>
              );
            })}
            {record.departments.length > 2 ? (
              <Tooltip
                title={record.departments
                  .slice(2, record.departments.length)
                  .map((dept) => {
                    return `[${dept.agentChannelID}] ${dept.name}`;
                  })
                  .join(', ')}
              >
                <SDepartmentShip>{`+${record.departments.length - 2}`}</SDepartmentShip>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      title: 'Action',
      width: '20%',
      render: (_: any, record: IQuickReply) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => {
                setIsOpenCreatePopup('edit');
                setNewDepartment(record.departments.map((e) => e.id));
                setNewFullMessage(record.fullMsg);
                setNewPreview(record.abbrMsg);
                setSelectedId(record.id);
              }}
              style={{ border: 'none', boxShadow: 'none', background: 'none' }}
            >
              <EditIcon />
            </Button>
            <Popconfirm
              title="Are you sure to delete this row?"
              onConfirm={() => deleteRow(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ border: 'none', boxShadow: 'none', background: 'none' }}>
                <DeleteIcon />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const handleTyping = (value: string) => {
    setSearchValue(value);
    setPageNo(1);
  };
  const handleTableChange = (newPagination: number) => {
    setPageNo(newPagination);
  };
  return (
    <Layout>
      <SContainer style={{ overflow: 'scroll' }}>
        {isOpenCreatePopup && (
          <div
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              top: 0,
              left: 0,
              background: 'rgba(0, 0, 0, 0.46)',
              zIndex: '100',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                background: 'white',
                width: '30%',
                padding: '30px 50px',
                borderRadius: '30px',
              }}
            >
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="title-popup"
              >
                {isOpenCreatePopup === 'create' ? <QuickReplyIcon /> : <EditIcon />}
                <div style={{ marginLeft: '5px' }}>
                  {isOpenCreatePopup === 'create' ? 'Create a quick reply' : 'Edit a quick reply'}
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <div>Preview</div>
                <div>
                  <Input
                    value={newPreview}
                    placeholder="Enter preview message"
                    onChange={(e) => {
                      setNewPreview(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <div>Full message</div>
                <div>
                  <TextArea
                    value={newFullMessage}
                    placeholder="Enter full message"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={(e) => {
                      setNewFullMessage(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <div>Departments</div>
                <div>
                  <>
                    {agentConstantContent && (
                      <div>
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="Select Department"
                          value={newDepartment} // Selected department IDs
                          onChange={(e) => setNewDepartment(e)} // Handle selection changes
                        >
                          {agentConstantContent.departments.map((dept) => (
                            <Option key={dept.id} value={dept.id}>
                              {`[${dept.agentChannelID}] ${dept.name}`}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    )}
                  </>
                </div>
              </div>
              <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => {
                    setNewDepartment([]);
                    setNewFullMessage('');
                    setNewPreview('');
                    setIsOpenCreatePopup(undefined);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={async () => {
                    await save();
                    setNewDepartment([]);
                    setNewFullMessage('');
                    setNewPreview('');
                    setIsOpenCreatePopup(undefined);
                  }}
                  style={{ marginLeft: '10px' }}
                  disabled={!newDepartment.length || !newFullMessage || !newPreview}
                >
                  {isOpenCreatePopup === 'create' ? 'Create' : 'Save changes'}
                </Button>
              </div>
            </div>
          </div>
        )}
        <SHeader>
          <div>
            <div style={{ fontSize: '20px', fontWeight: 700 }}>Agent Quick Reply</div>
            <div
              style={{ fontSize: '12px', fontWeight: 400 }}
            >{`จำนวนทั้งหมด ${quickReplyListData?.total} รายการ`}</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div style={{ width: '350px' }}>
              <Search
                placeholder="Search"
                value={searchValue}
                onChange={(e) => handleTyping(e.target.value)}
              />
            </div>
            <Button
              type="primary"
              onClick={addNewRow}
              style={{
                background: '#011688',
                height: '45px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '10px',
              }}
            >
              <div className="quick-reply-icon">
                <QuickReplyIcon width={'20px'} />
              </div>
              <div>Create a quick reply</div>
            </Button>
          </div>
        </SHeader>
        <Table
          dataSource={quickReplyList} // Use the data from the Zustand store
          columns={columns}
          rowKey="id"
          onChange={(e) => e.current && handleTableChange(e.current)}
          pagination={{
            current: pageNo,
            pageSize: PAGE_SIZE,
            total: quickReplyListData?.total || 1,
            showSizeChanger: false,
          }}
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        />
      </SContainer>
    </Layout>
  );
};

export default AgentQuickReply;
const SHeader = styled.div`
  display: grid;
  grid-template: 1fr/1fr 1fr;
  .quick-reply-icon {
    display: flex;
    margin-right: 5px;
    & > svg > path {
      fill: white;
    }
  }
`;
const SContainer = styled.div`
  .title-popup {
    & > svg {
      & > path {
        fill: black;
      }
    }
  }
`;
const SDepartmentShip = styled.div`
  background-color: #ebedf0;
  border-radius: 50px;
  white-space: nowrap;
  padding: 3px 10px;
  margin-bottom: 5px;
  margin-right: 5px;
`;

import { create } from 'zustand';
interface ILoadingStoreState {
  isLoading: boolean;
  turnOnLoading: () => void;
  turnOffLoading: () => void;
}
const loadingStore = create<ILoadingStoreState>((set) => ({
  isLoading: false,
  turnOnLoading: () => set(() => ({ isLoading: true })),
  turnOffLoading: () => set(() => ({ isLoading: false })),
}));
export default loadingStore;

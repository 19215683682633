import { Button, message, Select, Upload } from 'antd';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import { ReactComponent as EditButton } from '../../../../assets/edit.svg';
import { ReactComponent as Arrow } from '../../../../assets/arrow.svg';
import { ReactComponent as ImageIcon } from '../../../../assets/imageIcon.svg';
import { HookAPI } from 'antd/es/modal/useModal';
import {
  ICampaign,
  IRegistrationRecord,
} from '../../../../interfaces/warrantyRegistration.interface';
import { useEffect, useState } from 'react';
import { dateFormatter } from '../../../../utils/format';
import apiWarrantyRegistration from '../../../../apis/warrantyRegistration';
import { ReactComponent as WarningIcon } from '../../../../assets/warningIcon.svg';
import imageCompression from 'browser-image-compression';
interface IEdit {
  setContentState: React.Dispatch<React.SetStateAction<'table' | 'create' | 'edit'>>;
  modal: HookAPI;
  selectedRecord: IRegistrationRecord;
}
const Edit = ({ setContentState, modal, selectedRecord }: IEdit) => {
  const [imageUrl, setImageUrl] = useState('');
  const [campaignSelected, setCampaignSelected] = useState<any>(undefined);
  const [campaignList, setCampaignList] = useState<ICampaign[] | []>([]);
  const [slipBase64, setSlipBase64] = useState<{ contentType: string; content: string }>({
    contentType: '',
    content: '',
  });
  const getBase64 = (file: File, callback: (result: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result as string);
    reader.onerror = (error) => {
      message.error('File could not be read');
      console.error('Error: ', error);
    };
  };
  const handleUpload = async (file: File) => {
    const compressedFile = await compressImage(file);
    getBase64(compressedFile, (result) => {
      const base64Result = result.split('base64,');
      setImageUrl(result);
      setSlipBase64({ contentType: file.type, content: base64Result[1] });
    });
    return false; // Prevent default upload behavior
  };
  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Image compression failed:', error);
      return file;
    }
  };
  const handleGetCampaignList = async () => {
    const productType = await apiWarrantyRegistration.getProductType({
      serialNumber: selectedRecord.serialNumber,
      sku: selectedRecord.sku,
    });
    const campaignListResult = await apiWarrantyRegistration.getAvailableCampaign({
      registeredTime: new Date(selectedRecord.registeredAt).getTime(),
      sku: selectedRecord?.sku,
      productType: productType,
    });
    setCampaignList(campaignListResult);
  };
  useEffect(() => {
    handleGetCampaignList();
  }, []);
  const handleCampaignOption = () => {
    const result = [{ value: '-', label: '-' }];
    if (campaignList?.length) {
      campaignList.forEach((e) => {
        result.push({ value: e.campaignID, label: e.name });
      });
    }
    return result;
  };
  const handleEditWarranty = async () => {
    await apiWarrantyRegistration.editWarranty({
      serialNumber: selectedRecord.serialNumber,
      campaignID: campaignSelected?.campaignID
        ? campaignSelected?.campaignID
        : campaignSelected?.campaignID === ''
        ? ''
        : undefined,
      slipImage: !slipBase64.content || !slipBase64.contentType ? undefined : slipBase64,
    });
    setContentState('table');
  };
  const handleCampaignSelect = (value: string | any) => {
    if (value === '-') {
      setCampaignSelected({ campaignID: '' });
    } else {
      const result = campaignList.filter((e) => e.campaignID === value);
      setCampaignSelected(result[0]);
    }
  };
  return (
    <SContainer>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          modal.confirm({
            title: <div style={{ marginLeft: '5px' }}>Leave page?</div>,
            content: <>Any unsaved changes will be lost.</>,
            okText: 'Leave',
            cancelText: 'Keep editing',
            icon: <WarningIcon />,
            okButtonProps: {
              style: {
                backgroundColor: '#ff4d4f',
                borderColor: '#ff4d4f',
                color: '#fff',
              },
            },
            onOk() {
              setContentState('table');
            },
            onCancel() {},
          });
        }}
      >
        <div>
          <Arrow fill="#737373" />
        </div>
        <div>Back</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '10px', display: 'flex' }}>
          <EditButton />
        </div>
        <div>Edit Warranty Information</div>
      </div>
      <div>Warranty Information</div>
      <div>
        <div>
          <div>Customer</div>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '50px',
                height: '50px',
                marginRight: '10px',
                borderRadius: '50px',
                overflow: 'hidden',
              }}
            >
              <img
                style={{ width: '100%' }}
                src={selectedRecord?.social_channel?.imageUrl}
                alt="user-display"
              />
            </div>
            <div>
              <div>{selectedRecord?.customer.fullName}</div>
              <div>{selectedRecord?.customer.mobileNumber}</div>
            </div>
          </div>
        </div>
        <div>
          <div>Status</div>
          <div className={`status ${selectedRecord?.status.toLowerCase()}`}>
            {selectedRecord?.status}
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>Serial number</div>
          <div>{selectedRecord?.serialNumber}</div>
        </div>
        <div>
          <div>
            <div>Product name</div>
          </div>
          <div>{selectedRecord?.productName}</div>
        </div>
      </div>
      <div>
        <div>
          <div>Registration date</div>
          <div>
            {selectedRecord?.registeredAt ? dateFormatter(selectedRecord?.registeredAt) : '-'}
          </div>
        </div>
        <div>
          <div>Expired date</div>
          <div>{selectedRecord?.expiredAt ? dateFormatter(selectedRecord?.expiredAt) : '-'}</div>
        </div>
      </div>
      <div>
        <div>
          <div>Campaign hashtag</div>
          <div>
            <Select
              showSearch
              defaultValue={{
                value: selectedRecord?.campaignSku,
                label: selectedRecord?.campaignName,
              }}
              placeholder="Select a person"
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={handleCampaignSelect}
              options={handleCampaignOption()}
            />
          </div>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '5px' }}>Slip</div>
          <div style={{ color: '#BFBFBF' }}>(Optional)</div>
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            height: '100px',
          }}
        >
          <SImageBox>
            {imageUrl ? (
              <img src={imageUrl} alt="uploaded" />
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <ImageIcon />
                </div>
                <div>image</div>
              </div>
            )}
          </SImageBox>
          <Upload beforeUpload={handleUpload} showUploadList={false}>
            <Button icon={<UploadOutlined />}>Upload</Button>
            <div style={{ color: '#BFBFBF' }}>Upload .png or .jpeg files up to 10MB</div>
          </Upload>
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <Button
          style={{ width: '15%', marginRight: '20px', height: '50px' }}
          onClick={() => {
            setContentState('table');
          }}
        >
          Cancel
        </Button>
        <Button
          style={{ background: '#001689', color: 'white', width: '15%', height: '50px' }}
          onClick={() => {
            handleEditWarranty();
          }}
        >
          Save
        </Button>
      </div>
    </SContainer>
  );
};
export default Edit;
const SImageBox = styled.div`
  width: 100px;
  height: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-right: 20px;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }
`;
const SContainer = styled.div`
  & > div {
    display: flex;
    margin-top: 25px;
    & > div > div {
      height: 30px;
    }
    &:nth-child(1) {
      color: #737373;
      align-items: center;
      & > div > svg > g > path {
        fill: #737373;
      }
    }
    &:nth-child(2) {
      font-size: 20px;
      font-weight: bolder;
      align-items: center;

      & > div > svg {
        width: 25px;
        height: 25px;
        & > path {
          fill: black;
        }
      }
    }
    &:nth-child(3) {
      font-weight: bolder;
    }
    &:nth-child(7) {
      & > div {
        width: 95%;
      }
    }
    &:nth-child(8) {
      flex-direction: column;
      & > div {
        width: 95%;
      }
    }
    &:nth-child(9) {
      justify-content: flex-end;
      & > div {
        text-align: center;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        margin-top: 10px;
        padding: 10px 0px;
        width: 15%;
        cursor: pointer;
        margin-left: 10px;
        &:nth-child(2) {
          background-color: #001689;
          color: white;
        }
      }
    }
    &:nth-child(n + 4):nth-child(-n + 6) {
      & > div {
        width: 45%;
        &:nth-child(2) {
          margin-left: 5%;
        }
      }
    }
  }
`;

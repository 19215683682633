import { Modal } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import Layout from '../Layout';
import List from './List';
import CreateUpdate from './CreateUpdate';
import { IAgent } from '../../../../interfaces/agent.interface';

const AgentManagement = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [contentState, setContentState] = useState<'table' | 'create' | 'update'>('table');
  const [selectedRecord, setSelectedRecord] = useState<IAgent | undefined>(undefined);
  const renderContent = () => {
    switch (contentState) {
      case 'table':
        return <List setContentState={setContentState} setSelectedRecord={setSelectedRecord} />;
      case 'create':
        return (
          <CreateUpdate
            setContentState={setContentState}
            selectedRecord={undefined}
            modal={modal}
          />
        );
      case 'update':
        return (
          <CreateUpdate
            setContentState={setContentState}
            selectedRecord={selectedRecord}
            modal={modal}
          />
        );
    }
  };

  return (
    <Layout>
      <SContainer>
        {renderContent()}
        {contextHolder}
      </SContainer>
    </Layout>
  );
};
export default AgentManagement;

const SContainer = styled.div`
  height: 95% !important;
  .custom-table .ant-table-cell {
    font-size: 14px;
  }
  .no {
    color: #1890ff;
  }
  .customer {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      width: 30px;
      height: 30px;
      overflow: hidden;
      background: grey;
      border-radius: 50px;
      margin-right: 5px;
      & > img {
        height: 100%;
      }
    }
  }
  .hashtag {
    width: fit-content;
    border-radius: 100px;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdeaf2;
    color: #ed2e7b;
  }

  .option {
    cursor: pointer;
    position: relative;
  }
`;

import styled from 'styled-components';
import Top from './Top';
import Default from './content-components/Default';
import Profile from './content-components/Profile';
import Chatroom from './content-components/chatRoom/Chatroom';
import WarrantyRegistration from './content-components/warrantyRegistration/index';
import pageStore from '../../contexts/PageStore';
import PermissionMatrix from './content-components/PermissionMatrix';
import AgentManagement from './content-components/agentManagement/index';
import Customers from './content-components/customer/index';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import AgentQuickReply from './content-components/AgentQuickReply';
import Create from './content-components/warrantyRegistration/Create';

const CONTENT: { [x: string]: any } = {
  default: <Default />,
  profile: <Profile />,
  chat_room: <Chatroom />,
  warranty_register: <WarrantyRegistration />,
  admin_perm_matrix: <PermissionMatrix />,
  admin_user_management: <AgentManagement />,
  agent_quick_reply_config: <AgentQuickReply />,
  customer: <Customers />,
};
const Content = () => {
  const { contentSelected, setContentSelected } = pageStore();
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    const menuPath = currentPath.split('/home/')[1];
    setContentSelected(menuPath);
  }, []);

  return (
    <SContainer>
      <Top />
      {contentSelected && CONTENT[contentSelected]}
    </SContainer>
  );
};
export default Content;
const SContainer = styled.div`
  height: 100%;
  width: 85%;

  background-color: #fcfcfd;
  display: grid;
  grid-template: 80px 1fr/1fr;
  grid-gap: 10px;
`;

import { create } from 'zustand';
interface IRole {
  id: string;
  title: string;
}

interface IChannel {
  id: string;
  name: string;
  tagName: string;
}

interface ILanguage {
  id: string;
  name: string;
}
interface IAgentChannel {
  id: number;
  agentChannelID: string;
  name: string;
}
interface IAgentConstantContent {
  roles: IRole[];
  channels: IChannel[];
  languages: ILanguage[];
  departments: IAgentChannel[];
}
interface IManageAgentStoreState {
  agentConstantContent: IAgentConstantContent | undefined;
  setAgentConstantContent: (data: IAgentConstantContent) => void;
}
const manageAgentStore = create<IManageAgentStoreState>((set) => ({
  agentConstantContent: undefined,
  setAgentConstantContent: (data) => set(() => ({ agentConstantContent: data })),
}));
export default manageAgentStore;

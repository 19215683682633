import { useNavigate, useParams } from 'react-router-dom';
import Layout from './Layout.login';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { ReactComponent as Email } from '../../assets/login/email.svg';
import styled from 'styled-components';
import apiLogin from '../../apis/login';
import { useEffect, useState } from 'react';
const INITIAL_TIME_RESENT: number = 30;
const ResetPassword = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const [time, setTime] = useState<number>(INITIAL_TIME_RESENT);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isTimerActive && time > 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else if (time === 0) {
      setIsTimerActive(false);
      setTime(INITIAL_TIME_RESENT);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerActive, time]);

  const startTimer = (): void => {
    setIsTimerActive(true);
  };
  return (
    <Layout>
      <>
        <SHeader>
          <div
            onClick={() => {
              navigate('/login');
            }}
            className="back"
          >
            <Arrow />
          </div>
          <Email />
          <div className="topic">Check your email</div>
          <div className="sup-topic">We sent a password reset link to {email}</div>
        </SHeader>
        <SInputContainer>
          {isTimerActive ? (
            <SSubTextContainer>
              <div>Waiting {time}s to resend.</div>
            </SSubTextContainer>
          ) : (
            <SSubTextContainer>
              <div>Didn't receive the email?</div>
              <div
                className="link"
                onClick={() => {
                  apiLogin.requestChangePassword(email || '');
                  startTimer();
                }}
              >
                Click to resend
              </div>
            </SSubTextContainer>
          )}
        </SInputContainer>
      </>
    </Layout>
  );
};
export default ResetPassword;
const SSubTextContainer = styled.div`
  display: flex;
  margin-top: 20px;
  & > div {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #737373;
    &.link {
      cursor: pointer;
      text-decoration: underline;
      color: #1890ff;
      margin-left: 10px;
    }
  }
`;
const SInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  .login-form {
    max-width: 300px;
  }
  .login-form-forgot {
    float: right;
  }
`;
const SHeader = styled.div`
  width: 70%;
  margin-bottom: 30px;
  & > div {
    &.back {
      cursor: pointer;
      border-radius: var(--md, 8px);
      border: 1px solid var(--border-border-primary, #d9d9d9);
      background: var(--surface-surface-primary, #fff);
      width: fit-content;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
      padding: 10px;
      margin-bottom: 20px;
    }
    &.topic {
      font-weight: 500;
      line-height: 40px;
      font-size: 30px;
      margin-top: 20px;
    }
    &.sup-topic {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #737373;
    }
  }
`;

import { StateCreator, create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import apiLogin from '../apis/login';
import loadingStore from './LoadingStore';
import chatStore from './ChatStore';
import pageStore from './PageStore';

interface IAuthenticationStore {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  role: string;
  skills: string[];
  tags: string[];
  handleLogin: (
    email: string,
    password: string,
  ) => Promise<{ token: string; refreshToken: string }>;
  handleLogout: () => void;
  resetAuthState: () => void;
}
interface ITokenStore {
  token: string;
  refreshToken: string;
  handleLogin: (token: string, refreshToken: string) => Promise<boolean>;
  resetTokenState: () => void;
  handleLogout: () => void;
}

type IAuthenticationPersist = (
  config: StateCreator<IAuthenticationStore>,
  options: PersistOptions<IAuthenticationStore>,
) => StateCreator<IAuthenticationStore>;
type ITokenPersist = (
  config: StateCreator<ITokenStore>,
  options: PersistOptions<ITokenStore>,
) => StateCreator<ITokenStore>;

const INITIAL_STATE = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  skills: [],
  tags: [],
};
const INITIAL_TOKEN_STATE = {
  token: '',
  refreshToken: '',
};
const authenticationStore = (persist as IAuthenticationPersist)(
  (set, get) => ({
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    skills: [],
    tags: [],
    role: '',

    resetAuthState: () => {
      // Reset all state
      set(INITIAL_STATE);
    },
    handleLogout: () => {
      pageStore.getState().setContentSelected('default');
      chatStore.getState().resetAllChatList();
      chatStore.getState().resetChatSession();
      chatStore.getState().resetChatListTotalPage();
      chatStore.getState().resetChatSessionTotalPage();
      chatStore.getState().resetCustomerDetail();
      get().resetAuthState();
    },

    handleLogin: async (email: string, password: string) => {
      try {
        const userLogin = await apiLogin.login({
          id: email,
          password: password,
        });
        if (!userLogin) {
          return { token: '', refreshToken: '' };
        }
        const tagsHandle = userLogin.user.tags;
        const result = {
          ...userLogin.user,
          tags: tagsHandle.map((item: string) => item.trim()),
        };
        set(() => result);

        return { token: userLogin.token, refreshToken: userLogin.refreshToken };
      } catch (error) {
        console.error('zustand handleLogin error :', error);
        return { token: '', refreshToken: '' };
      }
    },
  }),
  { name: 'userAuth' },
);
const tokenStore = (persist as ITokenPersist)(
  (set, get) => ({
    token: '',
    refreshToken: '',

    resetTokenState: () => {
      // Reset all state
      set(INITIAL_TOKEN_STATE);
    },
    handleLogout: () => {
      get().resetTokenState();
    },

    handleLogin: async (token: string, refreshToken: string) => {
      try {
        const result = {
          token: token,
          refreshToken: refreshToken,
        };
        set(() => result);
        return true;
      } catch (error) {
        console.error('zustand handleLogin error :', error);
        return false;
      }
    },
  }),
  { name: 'tokenAuth' },
);

export const userAuthenticationStore = create<IAuthenticationStore>(authenticationStore);
export const tokenAuthenticationStore = create<ITokenStore>(tokenStore);

import { Button, Modal } from 'antd';
import apiLogin from '../../apis/login';
import { useNavigate } from 'react-router-dom';
import {
  userAuthenticationStore,
  tokenAuthenticationStore,
} from '../../contexts/UserAuthenticationStore';
interface IBaseButtonDialog {
  dialog: 'changePassword';
  customStyle?: { [x: string]: string | number };
}
const BaseButtonDialog = ({
  dialog,
  customStyle = { backgroundColor: '#001689', color: 'white' },
}: IBaseButtonDialog) => {
  const [modal, contextHolder] = Modal.useModal();
  const resetToken = tokenAuthenticationStore((state) => state.handleLogout);
  const { email, handleLogout } = userAuthenticationStore();
  const navigate = useNavigate();
  const DIALOG_CONTEXT = {
    changePassword: {
      buttonText: 'Change Password',
      dialog: {
        title: 'Do you want to change your password?',
        content: <>We will reset your password and send you an email with the reset link.</>,
        onOk() {
          apiLogin.requestChangePassword(email);
          handleLogout();
          resetToken();
          navigate(`/reset/${email}`);
        },
        onCancel() {
          console.log('Cancel');
        },
      },
    },
  };
  return (
    <>
      <Button
        style={customStyle}
        onClick={async () => {
          await modal.confirm(DIALOG_CONTEXT[dialog].dialog);
        }}
      >
        {DIALOG_CONTEXT[dialog].buttonText}
      </Button>
      {contextHolder}
    </>
  );
};
export default BaseButtonDialog;

import styled from 'styled-components';
import Layout from '../Layout';
import { Input, Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import ChatArea from './Chatarea';
import { IChatListSession } from '../../../../interfaces/chat.interface';
import { useNavigate, useLocation } from 'react-router-dom';
import { userAuthenticationStore } from '../../../../contexts/UserAuthenticationStore';
import { timeMessage } from '../../../../utils/format';
import chatStore from '../../../../contexts/ChatStore';
import useSocketStore from '../../../../contexts/SocketStore';
import defaultAvatar from '../../../../assets/default-avatar.jpg';
import loadingStore from '../../../../contexts/LoadingStore';
const { Search } = Input;
enum TAB {
  line = 'LINE',
  facebook = 'FACEBOOK_MESSENGER',
  webwidget = 'WEB_WIDGET',
}
const PAGE_SIZE = 100;
const Chatroom = () => {
  const { tags, id } = userAuthenticationStore();
  const { allChatList, resetAllChatList, pageApi, resetPageApi, chatListLoading } = chatStore();
  const { getChatList } = useSocketStore();
  const adminId = userAuthenticationStore((state) => state.id);

  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, turnOffLoading, turnOnLoading } = loadingStore();
  const [selectedSessionId, setSelectedSessionId] = useState<
    { status: string; session: string } | undefined | null
  >(undefined);
  const [selectedTab, setSelectedTab] = useState<'line' | 'facebook' | 'history' | 'webwidget'>(
    'line',
  );
  console.log(isLoading);
  const [selectedChannel, setSelectedChannel] = useState('LINE');
  const [displayChatList, setDisplayChatList] = useState<IChatListSession[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const handleGetChatList = async (
    page: number | undefined = undefined,
    q: string | undefined = undefined,
  ) => {
    turnOnLoading();
    await getChatList({
      tags: tags,
      agentID: id,
      status: selectedTab !== 'history' ? 'in-progress' : 'closed',
      channel: selectedTab === 'history' ? '' : selectedChannel,
      page: page || pageApi,
      pageSize: PAGE_SIZE,
      q: q === '' ? undefined : q,
    });
  };
  useEffect(() => {
    handleGetChatList(pageApi, searchValue || undefined);
  }, [pageApi]);
  useEffect(() => {
    if (pageApi === 1) {
      handleGetChatList(1);
    }
    resetPageApi();
  }, [selectedTab]);
  useEffect(() => {
    if (!allChatList.length) {
      setDisplayChatList([]);
    } else {
      let result = allChatList;
      if (selectedTab !== 'history') {
        result = result.filter((e) => {
          return TAB[selectedTab] === e.session.customer_social_channel.channel;
        });
      }
      setDisplayChatList(result);
      turnOffLoading();
    }
  }, [allChatList]);
  const searchTime = useRef<NodeJS.Timer>();
  const handleSearch = async (value: string) => {
    setSearchValue(value);
    clearInterval(searchTime.current);
    if (value) {
      searchTime.current = setInterval(async () => {
        await handleGetChatList(1, value || undefined);
        clearInterval(searchTime.current);
      }, 500);
    } else {
      await handleGetChatList(1);
    }
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const session = queryParams.get('session');
    const status = queryParams.get('status');
    if (!session || !status) return;
    setSelectedSessionId({ session: session || '', status: status || '' });
  }, [location]);

  return (
    <Layout>
      <SContainer style={{ height: '100%' }}>
        <SChatList ref={scrollContainerRef}>
          <div style={{ color: '#262626', fontWeight: '700', fontSize: '20px' }}>Messages</div>
          <SSearchBox>
            <Search
              placeholder="Search"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              value={searchValue}
            />
          </SSearchBox>
          <STabs>
            <SChip
              onClick={() => {
                if (chatListLoading) return;
                if (selectedTab !== 'line') {
                  setSelectedSessionId(undefined);
                  setSearchValue('');
                  resetAllChatList();
                  setSelectedChannel('LINE');
                  setSelectedTab('line');
                  navigate('/home/chat_room');
                }
              }}
              className={selectedTab === 'line' ? 'selected' : ''}
            >
              Line
            </SChip>
            <SChip
              onClick={() => {
                if (chatListLoading) return;
                if (selectedTab !== 'facebook') {
                  setSelectedSessionId(undefined);
                  setSearchValue('');
                  resetAllChatList();
                  setSelectedChannel('FACEBOOK_MESSENGER');
                  setSelectedTab('facebook');
                  navigate('/home/chat_room');
                }
              }}
              className={selectedTab === 'facebook' ? 'selected' : ''}
            >
              Facebook
            </SChip>
            <SChip
              onClick={() => {
                if (chatListLoading) return;
                if (selectedTab !== 'webwidget') {
                  setSelectedSessionId(undefined);
                  setSearchValue('');
                  resetAllChatList();
                  setSelectedChannel('WEB_WIDGET');
                  setSelectedTab('webwidget');
                  navigate('/home/chat_room');
                }
              }}
              className={selectedTab === 'webwidget' ? 'selected' : ''}
            >
              Webwidget
            </SChip>
            <SChip
              onClick={() => {
                if (chatListLoading) return;
                if (selectedTab !== 'history') {
                  setSelectedSessionId(undefined);
                  setSearchValue('');
                  resetAllChatList();
                  setSelectedTab('history');
                  navigate('/home/chat_room');
                }
              }}
              className={selectedTab === 'history' ? 'selected' : ''}
            >
              History
            </SChip>
          </STabs>
          {displayChatList.length ? (
            displayChatList.map((chat, i) => {
              return (
                <SChat
                  key={i}
                  className={selectedSessionId?.session === chat?.session?.id ? 'selected' : ''}
                  onClick={() => {
                    navigate(`?session=${chat?.session?.id}&status=${chat.session.status}`);
                  }}
                >
                  <div className="display-image">
                    <img
                      src={chat.session.customer_social_channel?.imageUrl || defaultAvatar}
                      alt={`user-${chat.session.customer_social_channel?.displayName}`}
                    />
                  </div>
                  <div className="name">
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#262626',
                      }}
                    >
                      <div
                        className="name"
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '60%',
                        }}
                      >
                        {chat.session.customer_social_channel?.displayName}
                      </div>
                      {selectedTab !== 'history' ? (
                        <div
                          className="tag"
                          style={
                            chat.session.assignAgentId
                              ? chat.session.assignAgentId === adminId
                                ? { color: '#7EE44E', borderColor: '#7EE44E', width: '30%' }
                                : { color: 'black', borderColor: 'black', width: '30%' }
                              : { color: '#FFD43C', borderColor: '#FFD43C', width: '30%' }
                          }
                        >
                          {chat.session.assignAgentId
                            ? chat.session.assignAgentId === adminId
                              ? 'owner'
                              : 'other'
                            : 'new'}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{
                        fontSize: '12px',
                        color: '#737373',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {chat.latestMessage?.content}
                    </div>
                  </div>
                  <div className="info">
                    <div style={{ fontSize: '10px', color: '#C0C0C0' }}>
                      {timeMessage(chat?.latestMessage?.updatedAt)}
                    </div>
                    {chat.newMessageCount && selectedTab !== 'history' ? (
                      <SAlertMessage>
                        <div>{chat.newMessageCount}</div>
                      </SAlertMessage>
                    ) : (
                      <></>
                    )}
                  </div>
                </SChat>
              );
            })
          ) : (
            <div>not found</div>
          )}
        </SChatList>
        {selectedSessionId?.session && (
          <ChatArea
            selectedTab={selectedTab}
            selectedSessionId={selectedSessionId.session}
            status={selectedSessionId.status}
            setSelectedSessionId={setSelectedSessionId}
          />
        )}
      </SContainer>
    </Layout>
  );
};
export default Chatroom;

const SChip = styled.div`
  width: fit-content;
  padding: 0px 15px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f4f7;
  color: #262626;
  border-radius: 50px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
  &.selected {
    background-color: #001689;
    color: #ffffff;
  }
`;
const SAlertMessage = styled.div`
  width: 16px;
  height: 16px;
  background-color: #f04438;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SChat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 60px;
  border-bottom: 1px solid #f0f0f0;
  overflow: hidden;
  cursor: pointer;
  &.selected {
    background-color: #f2f4f7;
    border-right: 2px solid #cf8a00;
  }
  &:hover {
    background-color: #f2f4f7;
  }
  & > div {
    &.display-image {
      width: 42.5px;
      height: 42.5px;
      position: relative;
      overflow: hidden;
      border-radius: 100px;
      background-color: grey;
      margin-right: 10px;
      & > img {
        height: 100%;
      }
    }
    &.name {
      width: 55%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      & > div {
        display: flex;
        & > div {
          &.tag {
            font-weight: bolder;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px black;
            padding: 0px 5px;
            font-size: 10px;
            border-radius: 40px;
          }
          &.name {
            max-width: 70%;
            margin-right: 5px;
          }
        }
      }
    }
    &.info {
      width: 20%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-evenly;
    }
  }
`;
const STabs = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;
const SSearchBox = styled.div`
  margin-top: 10px;
`;
const SContainer = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
`;
const SChatList = styled.div`
  width: 25%;
  /* height: 100%; */
  overflow-y: scroll;
  border-right: 1px solid #f0f0f0;
`;

import styled from 'styled-components';
import { ReactComponent as Arrow } from '../../../../assets/arrow.svg';
import { ReactComponent as Verify } from '../../../../assets/verify.svg';
import { ReactComponent as Chat } from '../../../../assets/chat.svg';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Select, Upload } from 'antd';
import { HookAPI } from 'antd/es/modal/useModal';
import { ICustomerData } from '../../../../interfaces/customer.interface';
import { dateFormatter } from '../../../../utils/format';
import { useEffect, useRef, useState } from 'react';
import apiAddress from '../../../../apis/address';
import {
  ILocationData,
  IProvince,
  IRegion,
  ISubdistrict,
} from '../../../../interfaces/address.interface';
import apiCustomer from '../../../../apis/customer';
import apiChat from '../../../../apis/chat';
import useSocketStore from '../../../../contexts/SocketStore';
import { userAuthenticationStore } from '../../../../contexts/UserAuthenticationStore';
import pageStore from '../../../../contexts/PageStore';
import { useNavigate } from 'react-router-dom';
import loadingStore from '../../../../contexts/LoadingStore';
import { ReactComponent as WarningIcon } from '../../../../assets/warningIcon.svg';
interface IEdit {
  setContentState: React.Dispatch<React.SetStateAction<'table' | 'edit'>>;
  modal: HookAPI;
  selectedRecord: ICustomerData;
}
interface IDistrict {
  districtId: number;
  postCode: string;
  code: string;
  nameTh: string;
  nameEn: string;
}
const Edit = ({ setContentState, modal, selectedRecord }: IEdit) => {
  const { setContentSelected } = pageStore();
  const { sendMessage } = useSocketStore();
  const { tags, id } = userAuthenticationStore();
  const selectedChannelRef = useRef<{ id: number; socialChannelId: string } | null>(null);
  const [postCodeInput, setPostCodeInput] = useState<string>('');
  const [districtList, setDistrictList] = useState<IDistrict[]>([]);
  const [districtSelected, setDistrictSelected] = useState<IDistrict | undefined>(undefined);
  const [subDistrictList, setSubDistrictList] = useState<ISubdistrict[]>([]);
  const [regionSelected, setRegionSelected] = useState<IRegion | undefined>(undefined);
  const [provinceSelected, setProvinceSelected] = useState<IProvince | undefined>(undefined);
  const [subDistrictSelected, setSubDistrictSelected] = useState<ISubdistrict | undefined>(
    undefined,
  );
  const { turnOnLoading, turnOffLoading } = loadingStore();
  const [consentSelect, setConsentSelect] = useState(selectedRecord.isAcceptConsent);
  const [tagsSelected, setTagsSelected] = useState<string[]>([]);
  const navigate = useNavigate();
  const handlePostcode = async () => {
    if (!postCodeInput) return;
    if (postCodeInput.length < 5) return;
    const result = await apiAddress.getDistrictListByPostCode(postCodeInput);
    setDistrictList(result.data);
    setDistrictSelected(result.data[0]);
  };
  const handleDistrict = async () => {
    if (!districtSelected) return;
    const result: { data: ILocationData } =
      await apiAddress.getSubDistrictListByPostCodeAndDistrict({
        postCode: postCodeInput,
        districtID: `${districtSelected.districtId}`,
      });
    setSubDistrictList(result.data.subdistricts);
    setSubDistrictSelected(result.data.subdistricts[0]);
    setRegionSelected(result.data.region);
    setProvinceSelected(result.data.province);
  };
  useEffect(() => {
    handleDistrict();
  }, [districtSelected]);
  useEffect(() => {
    handlePostcode();
  }, [postCodeInput]);
  const handleSave = async () => {
    const tagsAux: string[] = [];
    const tagsStd: number[] = [];
    if (tagsSelected?.length) {
      tagsSelected.forEach((e) => {
        if (parseInt(e)) {
          tagsStd.push(parseInt(e));
        } else {
          tagsAux.push(e);
        }
      });
    }
    const result = await apiCustomer.saveCustomer({
      userId: selectedRecord.customerId,
      subDistrictID: subDistrictSelected?.id || selectedRecord.customer_subdistrict_data.id,
      districtID: districtSelected?.districtId || selectedRecord.customer_district_data.id,
      provinceID: provinceSelected?.id || selectedRecord.customer_province_data.id,
      regionID: regionSelected?.id || selectedRecord.customer_region_data.id,
      postCode: postCodeInput,
      acceptConsent: consentSelect,
      tags: {
        aux: tagsAux,
        std: tagsStd,
      },
    });
    if (result) {
      turnOnLoading();
      const waitingUpdate = setInterval(() => {
        setContentState('table');
        turnOffLoading();
        clearInterval(waitingUpdate);
      }, 1000);
    } else {
      modal.error({
        title: 'Error',
        content: 'Something went wrong.',
      });
    }
  };
  const handleRedirect = async (sessionID: string) => {
    await window.open(`/home/chat_room?session=${sessionID}&status=in-progress`, '_self');
  };
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const handleGetStandardTags = async () => {
    const result = await apiCustomer.getStandardTags();
    if (result) {
      setOptions(
        result.customerTagList?.map((e: { id: string; name: string }) => {
          return { value: `${e.id}`, label: e.name };
        }),
      );
    }
    setTagsSelected(
      selectedRecord?.tags?.map((e) => {
        return `${e}`;
      }),
    );
  };
  useEffect(() => {
    handleGetStandardTags();
  }, []);

  const handleSelectChange = (value: string[]) => {
    if (tagsSelected.length === 10) return;
    setTagsSelected(value);
  };

  const handleSearch = (input: string) => {
    if (input && !options.some((option) => option.value === input)) {
      // Add the new input as an option
      setOptions((prevOptions) => [...prevOptions, { label: input, value: input }]);
    }
  };
  return (
    <SContainer>
      <div
        style={{ cursor: 'pointer', display: 'flex' }}
        onClick={() => {
          modal.confirm({
            title: <div style={{ marginLeft: '5px' }}>Leave page?</div>,
            content: <>Any unsaved changes will be lost.</>,
            okText: 'Leave',
            cancelText: 'Keep editing',
            icon: <WarningIcon />,
            okButtonProps: {
              style: {
                backgroundColor: '#ff4d4f',
                borderColor: '#ff4d4f',
                color: '#fff',
              },
            },
            onOk() {
              setContentState('table');
              navigate('/home/customer');
            },
            onCancel() {},
          });
        }}
      >
        <div>
          <Arrow fill="#737373" />
        </div>
        <div>Back</div>
      </div>
      <div>
        <div>Edit Customer Information</div>
      </div>
      <div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '5px' }}>Profile</div>
              <div style={{ color: '#BFBFBF' }}>(Optional)</div>
            </div>
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                height: '100px',
              }}
            >
              <SImageBox>
                <img
                  src={selectedRecord?.customer_social_channels?.[0]?.imageUrl || ''}
                  alt="uploaded"
                />
              </SImageBox>
              <Upload showUploadList={false}>
                <Button disabled icon={<UploadOutlined />}>
                  Upload
                </Button>
                <div style={{ color: '#BFBFBF' }}>Upload .png or .jpeg files up to 10MB</div>
              </Upload>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Button
              style={{ marginRight: '10px', display: 'flex' }}
              onClick={() => {
                navigate(`/home/warranty_register?id=${selectedRecord.customerId}`);
                setContentSelected('warranty_register');
              }}
            >
              <div
                style={{
                  marginRight: '5px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Verify />
              </div>
              <div>Register a warranty</div>
            </Button>
            <Button
              style={{ display: 'flex' }}
              onClick={async () => {
                await modal.confirm({
                  title: 'Select Channel',
                  content: (
                    <div style={{ width: '100%' }}>
                      <Select
                        onChange={(e) => {
                          selectedChannelRef.current = JSON.parse(e);
                        }}
                        placeholder="Select Channel"
                        style={{ width: '100%' }}
                        options={selectedRecord?.customer_social_channels
                          ?.filter((el) => el.channel !== 'WEB_WIDGET')
                          .map((e) => {
                            return {
                              value: JSON.stringify({ socialChannelId: e.customerId, id: e.id }),
                              label: `${e.channel} ${e.subChannel}`,
                            };
                          })}
                      />
                    </div>
                  ),
                  onOk: async () => {
                    // Use ref to get the most up-to-date value
                    const selectedChannel = selectedChannelRef.current;
                    if (selectedChannel?.id && selectedChannel.socialChannelId) {
                      const result = await apiChat.adminStartConversation({
                        customerID: selectedChannel.socialChannelId,
                        customerSocialChannelID: selectedChannel.id,
                      });
                      const message = {
                        content:
                          'สวัสดีครับ พี่ๆเจ้าหน้าที่กำลังจะเข้ามาคุยกับคุณลูกค้า กรุณารอสักครู่นะครับ',
                        senderFrom: 'AGENT',
                        sessionId: result.sessionID,
                        customerSocialId: `${selectedChannel.id}`,
                        senderId: id,
                        createdAt: new Date().toISOString(),
                        roomTags: tags,
                      };
                      await sendMessage(message);
                      handleRedirect(result.sessionID);
                    }
                  },
                  cancelText: 'Cancel',
                  icon: null,
                });
              }}
            >
              <div
                style={{
                  marginRight: '5px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Chat />
              </div>
              <div>Chat</div>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div style={{ fontWeight: 'bold' }}>Customer Information</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div style={{ width: '30%' }}>
            <div style={{ color: '#737373' }}>Customer name</div>
            <div>{`${selectedRecord.firstName} ${selectedRecord.lastName}`}</div>
          </div>
          <div style={{ width: '30%' }}>
            <div style={{ color: '#737373' }}>Phone number</div>
            <div>{selectedRecord.mobileNumber}</div>
          </div>
          <div style={{ width: '30%' }}>
            <div style={{ color: '#737373' }}>Email</div>
            <div>{selectedRecord.email}</div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div style={{ width: '30%' }}>
            <div style={{ color: '#737373' }}>Date of Birth</div>
            <div>{dateFormatter(selectedRecord.dob)}</div>
          </div>
          <div style={{ width: '65%' }}>
            <div style={{ color: '#737373' }}>Gender</div>
            <div>{selectedRecord.gender}</div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ fontWeight: 'bold' }}>Address Information</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div style={{ width: '30%' }}>
            <div style={{ color: '#737373' }}>Postcode</div>
            <div>
              <Input
                value={postCodeInput}
                maxLength={5}
                placeholder={selectedRecord.customer_subdistrict_data.postCode}
                onChange={(e) => {
                  setPostCodeInput(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <div style={{ color: '#737373' }}>Region</div>
            <div>
              <Input
                placeholder={postCodeInput ? '' : selectedRecord.customer_region_data.nameTh}
                value={regionSelected?.nameTh ? regionSelected.nameTh : ''}
                disabled
              />
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <div style={{ color: '#737373' }}>Province</div>
            <div>
              <Input
                placeholder={postCodeInput ? '' : selectedRecord.customer_province_data.nameTh}
                value={provinceSelected?.nameTh ? provinceSelected.nameTh : ''}
                disabled
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div style={{ width: '50%' }}>
            <div style={{ color: '#737373' }}>District</div>
            <div>
              <Select
                defaultValue={
                  districtList.length ? '' : selectedRecord.customer_district_data.nameTh
                }
                onChange={(e) => {
                  const result = districtList.filter((el) => `${el.districtId}` === `${e}`);
                  setDistrictSelected(result[0]);
                }}
                style={{ width: '99%' }}
                value={districtSelected?.districtId ? `${districtSelected?.nameTh}` : undefined}
                disabled={districtList.length ? false : true}
                options={districtList?.map((e) => {
                  return { value: e.districtId, label: e.nameTh };
                })}
              />
            </div>
          </div>
          <div style={{ width: '50%', marginLeft: '2%' }}>
            <div style={{ color: '#737373' }}>Subdistrict</div>
            <div>
              <Select
                defaultValue={selectedRecord.customer_subdistrict_data.nameTh}
                value={subDistrictSelected?.id ? `${subDistrictSelected?.nameTh}` : undefined}
                onChange={(e) => {
                  const result = subDistrictList.filter((el) => `${el.id}` === `${e}`);
                  setSubDistrictSelected(result[0]);
                }}
                style={{ width: '99%' }}
                disabled={subDistrictList.length ? false : true}
                options={subDistrictList?.map((e) => {
                  return { value: e.id, label: e.nameTh };
                })}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div style={{ width: '100%' }}>
            <div style={{ color: '#737373' }}>Tags</div>
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please typing or select"
                value={tagsSelected}
                onChange={handleSelectChange}
                onSearch={handleSearch}
                options={options}
                tokenSeparators={[',', ' ']}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', color: '#737373' }}>
        <div style={{ marginRight: '5px' }}>
          <Checkbox
            defaultChecked={selectedRecord.isAcceptConsent}
            disabled={selectedRecord.isAcceptConsent ? false : true}
            onChange={(e) => {
              setConsentSelect(e.target.checked);
            }}
          />
        </div>
        <div>
          The customer has consented to provide their information and receive promotions in
          accordance with the Privacy Policy.
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div
          style={{ width: '15%', marginRight: '20px' }}
          onClick={() => {
            setContentState('table');
          }}
        >
          <Button style={{ width: '100%', height: '50px' }}>Cancel</Button>
        </div>
        <div
          style={{ width: '15%' }}
          onClick={() => {
            handleSave();
          }}
        >
          <Button style={{ background: '#001689', color: 'white', width: '100%', height: '50px' }}>
            Save
          </Button>
        </div>
      </div>
    </SContainer>
  );
};
export default Edit;
const SImageBox = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 50px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  margin-right: 20px;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }
`;
const SContainer = styled.div`
  & > div {
    margin-top: 15px;

    &:nth-child(1) {
      color: #737373;
      align-items: center;
      & > div > svg > g > path {
        fill: #737373;
      }
    }
    &:nth-child(2) {
      font-size: 20px;
      font-weight: bolder;
      align-items: center;

      & > div > svg {
        width: 25px;
        height: 25px;
        & > path {
          fill: black;
        }
      }
    }
  }
`;

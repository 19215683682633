import { create } from 'zustand';
interface IPageStoreState {
  contentSelected: string | undefined;
  setContentSelected: (page: string) => void;
  menuList: { id: string; name: string }[] | [];
  setMenuList: (data: { id: string; name: string }[]) => void;
}

const pageStore = create<IPageStoreState>((set) => ({
  contentSelected: undefined,
  setContentSelected: (page) => set(() => ({ contentSelected: page })),
  menuList: [],
  setMenuList: (data) => set(() => ({ menuList: data })),
}));
export default pageStore;

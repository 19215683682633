import styled from 'styled-components';
import mainLogo from '../../assets/main-logo.png';
import { ReactComponent as Home } from '../../assets//home/home.svg';
import { ReactComponent as Chatroom } from '../../assets//home/chatroom.svg';
import { ReactComponent as AgentManagement } from '../../assets//home/agentManagement.svg';
import { ReactComponent as Customer } from '../../assets//home/customer.svg';
import { ReactComponent as Logout } from '../../assets//home/logout.svg';
import { ReactComponent as Warranty } from '../../assets//home/warrantyRegistration.svg';
import { ReactComponent as PermissionMatrix } from '../../assets//home/permissionMatrix.svg';
import { ReactComponent as ConfigIcon } from '../../assets/home/config.svg';
import { useNavigate } from 'react-router-dom';
import {
  tokenAuthenticationStore,
  userAuthenticationStore,
} from '../../contexts/UserAuthenticationStore';
import pageStore from '../../contexts/PageStore';
import loadingStore from '../../contexts/LoadingStore';
import useSocketStore from '../../contexts/SocketStore';

const MENU_LIST: {
  text: string;
  value:
    | 'default'
    | 'chat_room'
    | 'warranty_register'
    | 'admin_perm_matrix'
    | 'admin_user_management'
    | 'customer'
    | 'agent_quick_reply_config';
  components: string;
  icon: JSX.Element;
}[] = [
  { text: 'Home', value: 'default', components: '', icon: <Home /> },
  { text: 'Chatroom', value: 'chat_room', components: '', icon: <Chatroom /> },
  {
    text: 'Customer',
    value: 'customer',
    components: '',
    icon: <Customer />,
  },
  {
    text: 'Warranty Registration',
    value: 'warranty_register',
    components: '',
    icon: <Warranty />,
  },
  {
    text: 'Agent Management',
    value: 'admin_user_management',
    components: '',
    icon: <AgentManagement />,
  },
  {
    text: 'Agent Quick Reply',
    value: 'agent_quick_reply_config',
    components: '',
    icon: <ConfigIcon />,
  },
  {
    text: 'Permission Matrix',
    value: 'admin_perm_matrix',
    components: '',
    icon: <PermissionMatrix />,
  },
];
const Menu = () => {
  const handleLogout = userAuthenticationStore((state) => state.handleLogout);
  const { turnOnLoading, turnOffLoading } = loadingStore();
  const { logout } = useSocketStore();
  const { menuList } = pageStore();
  const handleTokenLogout = tokenAuthenticationStore((state) => state.handleLogout);
  const navigate = useNavigate();
  const { setContentSelected, contentSelected } = pageStore();

  return (
    <SContainer>
      <div>
        <img src={mainLogo} alt="logo" />
        {MENU_LIST.map((menu, i) => {
          if (
            menu.value === 'default' ||
            menuList.filter((item) => item.id.includes(menu.value)).length
          ) {
            return (
              <SChoice
                key={i}
                className={contentSelected === menu.value ? 'selected' : ''}
                onClick={() => {
                  setContentSelected(menu.value);
                  navigate(`/home/${menu.value}`);
                }}
              >
                <div className="icon">{menu.icon}</div>
                <div>{menu.text}</div>
              </SChoice>
            );
          }
        })}
      </div>
      <div>
        <SChoice
          className="logout"
          onClick={() => {
            turnOnLoading();
            logout();
            handleLogout();
            handleTokenLogout();
            turnOffLoading();
            navigate('/login');
          }}
        >
          <div className="icon">
            <Logout />
          </div>
          <div>Logout</div>
        </SChoice>
      </div>
    </SContainer>
  );
};
export default Menu;
const SChoice = styled.div`
  color: black;
  width: 75%;
  padding: 10px 15px;
  margin-top: 15px;
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & > div {
    &.icon {
      margin-right: 10px;
    }
  }
  &:not(.logout) {
    &:hover {
      background-color: #001689;
      color: white;
      & > div > svg > path {
        fill: white;
      }
      & > div > svg > g > path {
        fill: white;
      }
    }
    &.selected {
      background-color: #001689;
      color: white;
      & > div > svg > path {
        fill: white;
      }
      & > div > svg > g > path {
        fill: white;
      }
    }
  }
`;
const SContainer = styled.div`
  height: 100%;
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-right: 1px solid #d9d9d9;
  & > div {
    width: 75%;
  }
`;

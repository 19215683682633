import { Dropdown, MenuProps } from 'antd';
import styled from 'styled-components';
import pageStore from '../../../contexts/PageStore';

interface IUser {
  display: string | undefined;
  name: string | undefined;
  role: string | undefined;
}
const User = ({ display, name, role }: IUser) => {
  const { setContentSelected } = pageStore();
  const items: MenuProps['items'] = [
    {
      label: 'Profile',
      onClick: () => {
        setContentSelected('profile');
      },
      key: '0',
    },
  ];
  return (
    <SContainer>
      <Dropdown menu={{ items }}>
        <SUser>
          <SDisplay>
            <img src={display} alt="userDisplay" />
          </SDisplay>
          <div>
            <div style={{ fontSize: '14px', fontWeight: '400' }}>{name}</div>
            <div style={{ fontSize: '12px', fontWeight: '400', color: '#737373' }}>{role}</div>
          </div>
        </SUser>
      </Dropdown>
    </SContainer>
  );
};
export default User;
const SDisplay = styled.div`
  height: 3rem;
  width: 3rem;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background-color: grey;
  margin-right: 10px;
  & > img {
    height: 100%;
  }
`;
const SUser = styled.div`
  display: flex;
  padding: 0px 20px;
  height: 100%;
  align-items: center;
  cursor: pointer;
`;
const SContainer = styled.div`
  width: fit-content;
  height: 100%;
`;

import { instance } from './instance';
interface ILogin {
  id: string;
  password: string;
}
const login = async ({ id, password }: ILogin) => {
  try {
    const result = await instance.post('/user/login', {
      email: id,
      hash: password,
    });
    return result.data || undefined;
  } catch (err: any) {
    return undefined;
  }
};
const requestChangePassword = async (email: string) => {
  try {
    await instance.post(`/user/sendReset`, { email: email });
    return undefined;
  } catch (err: any) {
    return undefined;
  }
};
const changePassword = async (id: string, newPassword: string) => {
  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(newPassword);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    await instance.post(`/user/changePassword`, {
      uuid: id,
      password: hashHex,
    });
    return undefined;
  } catch (err: any) {
    return undefined;
  }
};
const checkChangePasswordRequest = async (id: string) => {
  try {
    const result = await instance.get(`/user/checkChangePasswordRequest/${encodeURIComponent(id)}`);
    return result.data.isAllow;
  } catch (err: any) {
    return undefined;
  }
};

const apiLogin = {
  checkChangePasswordRequest,
  login,
  requestChangePassword,
  changePassword,
};
export default apiLogin;

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './pages/login/Login';
import ForgotPassword from './pages/login/ForgotPassword.login';
import ResetPassword from './pages/login/ResetPassword.login';
import Home from './pages/home/Home';
import ChangePassword from './pages/login/ChangePassword.login';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';

function App() {
  const queryClient = new QueryClient();
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/forgot',
      element: <ForgotPassword />,
    },
    {
      path: '/reset/:email',
      element: <ResetPassword />,
    },
    {
      path: '/changePassword/:uuid',
      element: <ChangePassword />,
    },
    {
      path: '/*',
      element: <Home />,
    },
  ]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily:
            'IBM Plex Sans Thai, , Outfit, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
